// adaptation of https://github.com/benwinding/quill-html-edit-button

import { Quill } from 'vue2-editor';

function $create(elName) {
  return document.createElement(elName);
}
function $setAttr(el, key, value) {
  return el.setAttribute(key, value);
}

let debug = false;
const Logger = {
  prefixString() {
    return '</> quill-html-edit-button: ';
  },
  get log() {
    if (!debug) {
      return () => {};
    }
    // eslint-disable-next-line no-console
    const boundLogFn = console.log.bind(console, this.prefixString());
    return boundLogFn;
  },
};

function launchPopupEditor(quill, options) {
  const htmlFromEditor = quill.container.querySelector('.ql-editor').innerHTML;
  const popupContainer = $create('div');
  const overlayContainer = $create('div');
  const msg = options.msg || "Edit HTML here, when you click  the quill editor's contents will be replaced";
  const cancelText = options.cancelText || 'Cancel';
  const okText = options.okText || 'Ok';

  $setAttr(overlayContainer, 'class', 'ql-html-overlayContainer');
  $setAttr(popupContainer, 'class', 'ql-html-popupContainer');
  const popupTitle = $create('i');
  $setAttr(popupTitle, 'class', 'ql-html-popupTitle');
  popupTitle.innerText = msg;
  const textContainer = $create('div');
  textContainer.appendChild(popupTitle);
  $setAttr(textContainer, 'class', 'ql-html-textContainer');
  const textArea = $create('textarea');
  $setAttr(textArea, 'class', 'ql-html-textArea');
  textArea.value = (htmlFromEditor);
  const buttonCancel = $create('button');
  buttonCancel.innerHTML = cancelText;
  $setAttr(buttonCancel, 'class', 'btn btn-outline-info btn-md  ql-html-buttonCancel');
  const buttonOk = $create('button');
  buttonOk.innerHTML = okText;
  $setAttr(buttonOk, 'class', 'btn btn-info btn-md ');
  const buttonGroup = $create('div');
  $setAttr(buttonGroup, 'class', 'ql-html-buttonGroup');

  buttonGroup.appendChild(buttonCancel);
  buttonGroup.appendChild(buttonOk);
  textContainer.appendChild(textArea);
  textContainer.appendChild(buttonGroup);
  popupContainer.appendChild(textContainer);
  overlayContainer.appendChild(popupContainer);
  document.body.appendChild(overlayContainer);

  // eslint-disable-next-line func-names
  buttonCancel.onclick = function () {
    document.body.removeChild(overlayContainer);
  };
  overlayContainer.onclick = buttonCancel.onclick;
  popupContainer.onclick = function bar(e) {
    e.preventDefault();
    e.stopPropagation();
  };
  buttonOk.onclick = function ck() {
    const output = textArea.value.split(/\r?\n/g).map(el => el.trim());
    const noNewlines = output.join('');
    quill.container.querySelector('.ql-editor').innerHTML = noNewlines;
    document.body.removeChild(overlayContainer);
  };
}

class htmlEditButton {
  constructor(quill, options) {
    debug = options && options.debug;
    Logger.log('logging enabled');
    // Add button to all quill toolbar instances
    const toolbarModule = quill.getModule('toolbar');
    if (!toolbarModule) {
      throw new Error('qo');
    }
    this.registerDivModule();
    const toolbarEl = toolbarModule.container;
    const buttonContainer = $create('span');
    $setAttr(buttonContainer, 'class', 'ql-formats');
    const button = $create('button');
    button.innerHTML = options.buttonHTML || '&lt;&gt;';
    button.title = options.buttonTitle || 'Show HTML source';
    button.onclick = function bar(e) {
      e.preventDefault();
      launchPopupEditor(quill, options);
    };
    buttonContainer.appendChild(button);
    toolbarEl.appendChild(buttonContainer);
  }

  // eslint-disable-next-line class-methods-use-this
  registerDivModule() {
    // To allow divs to be inserted into html editor
    // obtained from issue: https://github.com/quilljs/quill/issues/2040
    const Block = Quill.import('blots/block');
    class Div extends Block {}
    Div.tagName = 'div';
    Div.blotName = 'div';
    Div.allowedChildren = Block.allowedChildren;
    Div.allowedChildren.push(Block);
    Quill.register(Div);
  }
}

window.htmlEditButton = htmlEditButton;
export default htmlEditButton;
export { htmlEditButton };
