<template>
  <div>
    <vue-dropzone
      :id="id"
      ref="vueDropzone"
      class="email-uploader model"
      :options="dropZoneOptions"
      @vdropzone-success="fileUploaded"
      @vdropzone-sending="onSending"
      @vdropzone-file-added="onAdded"
      @vdropzone-removed-file="onRemove"
      @vdropzone-queue-complete="onComplete"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';


export default {
  name: 'FileUploader',
  props: {
    id: {
      type: String,
      required: true,
    },
    maxFileSize: {
      type: Number,
      default: 10,
    },
    timeout: {
      type: Number,
      default: 1800000, // 30m
    },
    dictDefaultMessage: {
      type: String,
      default: 'Drop files here or click to attach',
    },
  },
  data() {
    return {
      preSignedUrl: '#',
      totalSize: 0,
      template: `<div class="dz-preview dz-file-preview">
                    <div class="dz-size" data-dz-size></div>
                    <div class="dz-details">
                      <div class="dz-filename"><div data-dz-name></div></div>
                      <img data-dz-thumbnail />
                    </div>
                    <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                    <div class="dz-success-mark"><span><i class="ion ion-md-checkmark"></i></span></div>
                    <div class="dz-error-mark"><span><i class="ion ion-md-close "></i></span></div>
                    <div class="dz-error-message"><span data-dz-errormessage></span></div>
                  </div>
                  `,
    };
  },
  computed: {
    dropZoneOptions() {
      const that = this;
      return {
        url: this.preSignedUrl,
        maxFilesize: this.maxFileSize,
        timeout: this.timeout,
        dictDefaultMessage: this.dictDefaultMessage || this.placeholder || 'Drop files here or click to upload',
        autoProcessQueue: true,
        acceptedFiles: '.png, .jpeg, .jpg, .pdf, .txt, .doc, .docx, .ppt, .xlsx, .xls, .csv, .pptx',
        createImageThumbnails: false,
        addRemoveLinks: true,
        dictRemoveFile: '<i class="ion ion-md-trash" style="font-size:20px; cursor: pointer;" />',
        dictCancelUpload: '<i class="ion ion-md-trash" style="font-size:20px; cursor: pointer;" />',
        previewTemplate: this.template,
        parallelUploads: 1,
        accept(file, done) {
          that.totalSize += parseFloat((file.size / (1024 * 1024)).toFixed(2));
          if (that.totalSize >= that.maxFileSize) {
            done('The Max filesize in Attachments has been exceeded: 10MiB.');
          } else {
            that.getUploadLink({ issuerId: that.$route.params.idIssuer }).then(({ url, fields, fileKey }) => {
              that.preSignedUrl = url;
              if (that.$refs.vueDropzone) {
                that.$refs.vueDropzone.setOption('url', url);
                Object.assign(file, { fields, fileKey });
                done();
              }
            });
          }
        },
        init() {
          this.removeAllFiles();
        },
      };
    },
  },
  methods: {
    ...mapActions('outreach', ['getUploadLink']),
    onRemove(file) {
      if (file.status !== 'error') {
        this.totalSize -= parseFloat((file.size / (1024 * 1024)).toFixed(2));
        this.$emit('onRemove', file);
      }
    },
    fileUploaded(file) {
      this.$emit('onChange', {
        fileStatus: file.status,
        fileName: file.name,
        fileKey: file.fileKey,
      });
    },
    onSending(file, req, formData) {
      formData.append('content-type', file.type);
      Object.entries(file.fields).forEach(([key, value]) => formData.append(key, value));
    },
    onAdded(file) {
      this.$emit('onChange', {
        fileStatus: file.status,
      });
    },
    onComplete() {
      this.$emit('onComplete', {});
    },
  },
};
</script>

<style src="~/../node_modules/vue2-dropzone/dist/vue2Dropzone.min.css"></style>
<style lang="scss">
.email-uploader.small-footprint.document-error {
  border: 1px solid red;
  border-radius: 5px;
}
.small-footprint.email-uploader.dropzone {
  min-height: 100px;
  padding: 10px;
}
.dropzone.show-message div.dz-message {
  display: block;
  font-size: 1.0rem;
}
.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
}
.email-uploader.dropzone {
  border: 3px dashed rgba(10, 23, 39, 0.06);
  padding: 0 0 20px 0;
  .dz-progress{
    width: 80%;
    left: 30%;

  }
  .dz-remove{
    bottom: -5px;
    right: 3px;
    padding: 0px;
    box-shadow: transparent;
    border: 0px;
    opacity: 1;
    color: #1f537a;
  }
  .dz-preview{
    width: 200px;
    height: 20px;
    margin-top:20px;
    min-height: 20px;
  }
  .dz-filename{
    font-size: 15px;
    font-weight: bold !important;
    text-align: left;
    height: 20px;
    margin-bottom: 10px;
    display: flex;
    align-text: center;
    width: 75%;
    top: 5px;
    position: absolute;
  }
  .dz-filename div{
    text-overflow: ellipsis;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dz-size{
    position: absolute;
    bottom: 17px;
    font-size: 10px;
    font-weight: 200;
    left: 6px;
  }
  .dz-details{
    display: inline-block;
    font-size: 0.858em;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.125rem;
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;

    background-color: transparent;
    -webkit-box-shadow: 0 0 0 1px #1f537a inset;
    box-shadow: 0 0 0 1px #1f537a inset;
    color: #1f537a;

    padding: 1em 0em;

  }
  .dz-error-mark {
    svg {
      border-radius: 50%;
      background-color: rgba(255, 0, 0, 0.7);
      border: 1px rgba(255, 0, 0, 0.7) solid;
    }
  }
  .dz-success-mark {
    background: none;
    box-shadow: none;
    color: green;
    left: 165px;
    bottom: 16px;
  }
  .dz-error-mark {
    left: 165px;
    bottom: 16px;
    color: red;
  }
  .dz-error-message{
    top: 25px;
  }
  .dz-message {
    padding: 0;
    font-weight: 500;
    font-size: 1.313rem;
  }
  .dz-preview .dz-progress{
    top: 35px;
  }
  .dz-preview .dz-progress .dz-upload{
    display: block;
    position: absolute;
    background-color: #28a745 !important;
  }
  .dz-image{
    background: transparent !important;
  }
}
</style>
