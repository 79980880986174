export const registrationStatus = {
  styles: {
    none: 'secondary',
    pending: 'warning',
    syncing: 'primary',
    ready: 'success',
  },
  labels: {
    none: 'outreach.status.walletRegistration.none',
    pending: 'outreach.status.walletRegistration.pending',
    syncing: 'outreach.status.walletRegistration.syncing',
    ready: 'outreach.status.walletRegistration.ready',
    rejected: 'outreach.status.walletRegistration.rejected',
    cancelled: 'outreach.status.walletRegistration.cancelled',
  },
};


export const kycStatus = {
  styles: {
    none: 'default',
    pending: 'warning',
    processing: 'warning',
    'manual-review': 'warning',
    manual_review: 'warning',
    'processing-error': 'danger',
    processing_error: 'danger',
    rejected: 'danger',
    passed: 'success',
  },
  labels: {
    none: 'outreach.status.kyc.none',
    pending: 'outreach.status.kyc.pending',
    processing: 'outreach.status.kyc.processing',
    'manual-review': 'outreach.status.kyc.manualReview',
    manual_review: 'outreach.status.kyc.manualReview',
    'processing-error': 'outreach.status.kyc.processingError',
    processing_error: 'outreach.status.kyc.processingError',
    rejected: 'outreach.status.kyc.rejected',
    passed: 'outreach.status.kyc.passed',
  },
};

export const stageList = [
  { text: 'Onboarding', value: 'onboarding' },
  { text: 'Fundraise', value: 'fundraise' },
  { text: 'Holders', value: 'holders' },
];

export const filterLists = {
  walletStatus: {
    name: 'walletStatus',
    options: [
      { i18nKey: 'outreach.label.filter.walletStatus', value: null },
      { i18nKey: 'investorsOnboarding.option.walletStatus.pending', value: 'pending' },
      { i18nKey: 'investorsOnboarding.option.walletStatus.syncing', value: 'syncing' },
      { i18nKey: 'investorsOnboarding.option.walletStatus.ready', value: 'ready' },
      { i18nKey: 'investorsOnboarding.option.walletStatus.rejected', value: 'rejected' },
      { i18nKey: 'investorsOnboarding.option.walletStatus.cancelled', value: 'cancelled' },
    ],
  },
  investorType: {
    name: 'investorType',
    options: [
      { i18nKey: 'outreach.label.filter.investorType', value: null },
      { i18nKey: 'common.category.investorType.individual', value: 'individual' },
      { i18nKey: 'common.category.investorType.entity', value: 'entity' },
    ],
  },
  kycStatus: {
    name: 'kycStatus',
    options: [
      { i18nKey: 'outreach.label.filter.kycStatus', value: null },
      { i18nKey: 'investorsOnboarding.option.kycStatus.none', value: 'none' },
      { i18nKey: 'investorsOnboarding.option.kycStatus.processing', value: 'processing' },
      { i18nKey: 'investorsOnboarding.option.kycStatus.manualReview', value: 'manual-review' },
      { i18nKey: 'investorsOnboarding.option.kycStatus.updatesRequired', value: 'updates-required' },
      { i18nKey: 'investorsOnboarding.option.kycStatus.verified', value: 'verified' },
      { i18nKey: 'investorsOnboarding.option.kycStatus.rejected', value: 'rejected' },
      { i18nKey: 'investorsOnboarding.option.kycStatus.expired', value: 'expired' },
    ],
  },
  investorStatus: {
    name: 'investorStatus',
    disabled: true,
    options: [
      { i18nKey: 'outreach.label.filter.investorStatus', value: null },
      { i18nKey: 'outreach.option.investorStatus.pendingKyc', value: 'pending-kyc' },
      { i18nKey: 'outreach.option.investorStatus.pendingAccreditation', value: 'pending-accreditation' },
      { i18nKey: 'outreach.option.investorStatus.pendingInvestorSignature', value: 'pending-investor-signature' },
      { i18nKey: 'outreach.option.investorStatus.pendingQualification', value: 'pending-qualification' },
      { i18nKey: 'outreach.option.investorStatus.pendingIssuerSignature', value: 'pending-issuer-signature' },
      { i18nKey: 'outreach.option.investorStatus.pendingFunding', value: 'pending-funding' },
      { i18nKey: 'outreach.option.investorStatus.ready', value: 'ready' },
    ],
  },
  accreditationStatus: {
    name: 'accreditationStatus',
    options: [
      { i18nKey: 'outreach.label.filter.accreditationStatus', value: null },
      { i18nKey: 'investorsOnboarding.option.accreditedStatusList.no', value: 'no' },
      { i18nKey: 'investorsOnboarding.option.accreditedStatusList.noAccepted', value: 'no-accepted' },
      { i18nKey: 'investorsOnboarding.option.accreditedStatusList.pending', value: 'pending' },
      { i18nKey: 'investorsOnboarding.option.accreditedStatusList.confirmed', value: 'confirmed' },
      { i18nKey: 'investorsOnboarding.option.accreditedStatusList.rejected', value: 'rejected' },
      { i18nKey: 'investorsOnboarding.option.accreditedStatusList.none', value: 'none' },
      { i18nKey: 'investorsOnboarding.option.accreditedStatusList.expired', value: 'expired' },
      { i18nKey: 'investorsOnboarding.option.accreditedStatusList.updatesRequired', value: 'updates-required' },
    ],
  },
  fundingStatus: {
    name: 'fundingStatus',
    options: [
      { i18nKey: 'outreach.label.filter.fundingStatus', value: null },
      { i18nKey: 'outreach.option.fundingStatus.commited', value: 'pledged' },
      { i18nKey: 'outreach.option.fundingStatus.funded', value: 'funded' },
    ],
  },
  percentage: {
    name: 'percentage',
    options: [
      { i18nKey: 'outreach.label.filter.percentage', value: null },
      { i18nKey: 'outreach.option.percentage.ten', value: 10 },
      { i18nKey: 'outreach.option.percentage.quarter', value: 25 },
      { i18nKey: 'outreach.option.percentage.half', value: 50 },
      { i18nKey: 'outreach.option.percentage.threeQuarter', value: 75 },
    ],
  },
  subscriptionAgreementStatus: {
    name: 'subscriptionAgreementStatus',
    disabled: true,
    options: [
      { i18nKey: 'outreach.label.filter.subscriptionAgreementStatus', value: null },
      { i18nKey: 'investorsFundraise.option.subscriptionAgreement.none', value: 'none' },
      { i18nKey: 'investorsFundraise.option.subscriptionAgreement.requested', value: 'requested' },
      { i18nKey: 'investorsFundraise.option.subscriptionAgreement.sent', value: 'sent' },
      { i18nKey: 'investorsFundraise.option.subscriptionAgreement.signed', value: 'signed' },
      { i18nKey: 'investorsFundraise.option.subscriptionAgreement.confirmed', value: 'confirmed' },
      { i18nKey: 'investorsFundraise.option.subscriptionAgreement.rejected', value: 'rejected' },
      { i18nKey: 'investorsFundraise.option.subscriptionAgreement.preSigned', value: 'pre-signed' },
    ],
  },
};

export const tableFields = {
  name: {
    key: 'name', i18nKey: 'outreach.label.table.investorName', sortable: true, tdClass: 'align-middle', thClass: 'pt-2 pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  email: {
    key: 'email', i18nKey: 'outreach.label.table.emailAddress', sortable: false, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  country: {
    key: 'countryCode', i18nKey: 'outreach.label.table.country', sortable: true, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  investorType: {
    key: 'investorType', i18nKey: 'outreach.label.table.investorType', sortable: true, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  investorStatus: {
    key: 'investorStatus', i18nKey: 'outreach.label.table.investorStatus', sortable: true, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  amount: {
    key: 'totalTokens', i18nKey: 'outreach.label.table.tokenAmount', sortable: true, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  percentage: {
    key: 'percentage', i18nKey: 'outreach.label.table.percentage', sortable: false, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  kycStatus: {
    key: 'kycStatus', i18nKey: 'outreach.label.table.kycStatus', sortable: true, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  walletStatus: {
    key: 'walletRegistrationStatus', i18nKey: 'outreach.label.table.walletRegistrationStatus', sortable: true, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  accreditationStatus: {
    key: 'accreditationStatus', i18nKey: 'outreach.label.table.accreditationStatus', sortable: true, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  invested: {
    key: 'invested', i18nKey: 'outreach.label.table.investedStatus', sortable: true, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  round: {
    key: 'roundIds', i18nKey: 'outreach.label.table.rounds', sortable: false, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  labels: {
    key: 'labels', i18nKey: 'outreach.label.table.labels', sortable: false, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  fundingStatus: {
    key: 'fundingStatus', i18nKey: 'outreach.label.table.fundingStatus', sortable: false, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  subscriptionAgreementStatus: {
    key: 'subscriptionAgreementStatus', i18nKey: 'investorsFundraise.label.table.subscriptionAgreementStatus', sortable: true, tdClass: 'align-middle', thClass: 'pt-2 border-top-0', thStyle: { minWidth: '140px' },
  },
  subject: {
    key: 'subject', i18nKey: 'outreach.label.table.subject', sortable: false, tdClass: 'text-left', thClass: 'pt-2 pl-2 border-top-0', thStyle: { minWidth: '120px' },
  },
  date: {
    key: 'date', i18nKey: 'outreach.label.table.date', sortable: true, tdClass: 'text-center align-middle', thClass: 'border-top-0 text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  recipients: {
    key: 'recipients', i18nKey: 'outreach.label.table.recipients', sortable: true, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  delivered: {
    key: 'delivered', i18nKey: 'outreach.label.table.delivered', sortable: true, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  bounce: {
    key: 'bounce', i18nKey: 'outreach.label.table.bounce', sortable: true, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  open: {
    key: 'open', i18nKey: 'outreach.label.table.open', sortable: true, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  click: {
    key: 'click', i18nKey: 'outreach.label.table.click', sortable: true, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  unsubscribe: {
    key: 'unsubscribe', i18nKey: 'outreach.label.table.unsubscribe', sortable: true, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  spamreport: {
    key: 'spamreport', i18nKey: 'outreach.label.table.spamreport', sortable: true, tdClass: 'text-center align-middle', thClass: 'border-top-0  text-center pt-2 pl-2', thStyle: { minWidth: '120px' },
  },
  actions: {
    key: 'id', label: '', tdClass: 'align-middle', thClass: 'border-top-0 text-center ', thStyle: { minWidth: '120px' },
  },
  emailActivity: {
    key: 'email', i18nKey: 'outreach.label.table.emailAddress', sortable: true, tdClass: 'text-left', thClass: 'border-top-0 text-left pl-2 pt-2', thStyle: { minWidth: '120px' },
  },
  nameActivity: {
    key: 'name', i18nKey: 'outreach.label.table.investorName', sortable: false, tdClass: 'text-left', thClass: 'border-top-0 text-left pl-2 pt-2', thStyle: { minWidth: '120px' },
  },
};

export const tableColumns = {
  onboarding: [
    tableFields.name,
    tableFields.email,
    tableFields.country,
    tableFields.investorType,
    tableFields.kycStatus,
  ],
  fundraise: [
    tableFields.name,
    tableFields.email,
    tableFields.country,
    tableFields.investorType,
    tableFields.investorStatus,
    tableFields.fundingStatus,
    tableFields.subscriptionAgreementStatus,
    tableFields.round,
    tableFields.labels,
  ],
  holders: [
    tableFields.name,
    tableFields.email,
    tableFields.country,
    tableFields.investorType,
    tableFields.walletStatus,
    tableFields.amount,
    tableFields.percentage,
    tableFields.labels,
  ],
  emailActivity: [
    tableFields.subject,
    tableFields.date,
    tableFields.recipients,
    tableFields.delivered,
    tableFields.bounce,
    tableFields.open,
    tableFields.click,
    tableFields.unsubscribe,
    tableFields.spamreport,
    tableFields.actions,
  ],
  emailActivityDetails: [
    tableFields.nameActivity,
    tableFields.emailActivity,
    tableFields.delivered,
    tableFields.bounce,
    tableFields.open,
    tableFields.click,
    tableFields.unsubscribe,
    tableFields.spamreport,
  ],
};

export const filters = {
  onboarding: [
    filterLists.investorType,
    filterLists.kycStatus,
    // filterLists.walletStatus,
  ],
  fundraise: [
    filterLists.investorType,
    filterLists.fundingStatus,
  ],
  fundraiseRounds: [
    filterLists.investorStatus,
    filterLists.subscriptionAgreementStatus,
  ],
  holders: [
    filterLists.investorType,
    // filterLists.percentage,
    // labels,
  ],
};
