var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('cp-button',{staticClass:"pt-1 pb-1 mr-2 float-left",attrs:{"variant":"info","size":"md"},on:{"click":_vm.goBack}},[_c('span',[_vm._v(_vm._s(_vm.$t('outreach.dashboard.emailActivity.back')))])])],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"float-left text-left mt-3"},[_c('p',[_c('u',[_vm._v(_vm._s(_vm.$t('outreach.dashboard.emailActivity.emailSubject'))+" "+_vm._s(_vm.data.subject))])]),_c('p',[_c('u',[_vm._v(_vm._s(_vm.$t('outreach.dashboard.emailActivity.dateSent'))+" "+_vm._s(_vm.getDate(_vm.data.date))+" ")])])])]),_c('b-col',[_c('out-table',{attrs:{"items":_vm.data.data,"fields":_vm.tableFields,"query-params":_vm.queryParamsDetail,"total-items":_vm.totalItems,"busy":_vm.isLoading},on:{"onPagination":function($event){return _vm.$emit('onPagination', $event, 'detail')}},scopedSlots:_vm._u([{key:"delivered",fn:function(ref){
var rowData = ref.rowData;
return [_c('i',{staticClass:"ion",class:_vm.getIcon(rowData.item.events.indexOf('delivered'))})]}},{key:"bounce",fn:function(ref){
var rowData = ref.rowData;
return [_c('i',{staticClass:"ion",class:_vm.getIcon(rowData.item.events.indexOf('bounce'))})]}},{key:"open",fn:function(ref){
var rowData = ref.rowData;
return [_c('i',{staticClass:"ion",class:_vm.getIcon(rowData.item.events.indexOf('open'))})]}},{key:"click",fn:function(ref){
var rowData = ref.rowData;
return [_c('i',{staticClass:"ion",class:_vm.getIcon(rowData.item.events.indexOf('click'))})]}},{key:"unsubscribe",fn:function(ref){
var rowData = ref.rowData;
return [_c('i',{staticClass:"ion",class:_vm.getIcon(rowData.item.events.indexOf('unsubscribe'))})]}},{key:"spamreport",fn:function(ref){
var rowData = ref.rowData;
return [_c('i',{staticClass:"ion",class:_vm.getIcon(rowData.item.events.indexOf('spamreport'))})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }