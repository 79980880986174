<template>
  <div>
    <out-table
      :items="data"
      :fields="tableFields"
      :busy="tableLoading"
      :query-params="queryParams"
      :total-items="totalItems"
      @onPagination="$emit('onPagination', $event)"
    >
      <template
        slot="countryCode"
        slot-scope="{ rowData }"
      >
        <cp-country-name
          :country-code="rowData.value"
        />
      </template>
      <template
        slot="investorType"
        slot-scope="{ rowData }"
      >
        {{ getInvestorType(rowData.value) }}
      </template>
      <template
        slot="fundingStatus"
        slot-scope="{ rowData }"
      >
        <cp-funding-status
          :amount-funded="rowData.item.amountFunded"
          :amount-pledged="rowData.item.amountPledged"
        />
      </template>
      <template
        slot="kycStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom ml-1"
          :variant="kycStatus.styles[rowData.value]"
        >
          {{ $t(kycStatus.labels[rowData.value]) || '' }}
        </b-badge>
      </template>
      <template
        slot="investorStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          v-b-tooltip.hover="true"
          style="cursor:pointer"
          :variant="investStatusData(rowData.value).color"
        >
          {{ investStatusData(rowData.value).status }}
        </b-badge>
      </template>
      <template
        slot="subscriptionAgreementStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom ml-1"
          :variant="statuses.subscription[rowData.value]"
        >
          {{ $t(subscriptionStatusLabels[rowData.value]) }}
        </b-badge>
      </template>
      <template
        slot="roundIds"
        slot-scope="{ rowData }"
      >
        <div v-if="isRounds(rowData.value)">
          <span
            v-for="id in getRoundsList(rowData.value)"
            :key="id"
          >
            <b-badge
              v-if=" findNameById(id) != false"
              class="align-text-bottom ml-1"
              variant="outline-secondary"
            >
              {{ findNameById(id) }}
            </b-badge>
          </span>
          <span
            v-if="rowData.value.length > 3"
            v-b-tooltip.hover="true"
            :title="getRoundsTitle(rowData.value)"
            style="cursor: pointer"
          >
            + {{ rowData.value.length - 3 }}
          </span>
        </div>
      </template>

      <template
        slot="percentage"
        slot-scope="{ rowData }"
      >
        <div class="no-break">
          <cp-ellipsis
            class="no-break"
            :data-value="rowData.value"
          >
            %
          </cp-ellipsis>
        </div>
      </template>

      <template
        slot="walletStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom ml-1"
          :variant="registrationStatus.styles[rowData.value]"
        >
          {{ $t(registrationStatus.labels[rowData.value]) }}
        </b-badge>
      </template>

      <template
        slot="labels"
        slot-scope="{ rowData }"
      >
        <div v-if="isLabels(rowData.value)">
          <span
            v-for="label in getLabelsList(rowData.value)"
            :key="label"
          >
            <b-badge
              v-if="label"
              class="align-text-bottom ml-1"
              variant="outline-secondary"
            >
              {{ label }}
            </b-badge>
          </span>
          <span
            v-if="rowData.value.length > 3"
            v-b-tooltip.hover="true"
            :title="getLabelsTitle(rowData.value)"
            style="cursor: pointer"
          >
            + {{ rowData.value.length - 3 }}
          </span>
        </div>
      </template>
    </out-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Cookie from 'js-cookie';
import CpCountryName from '~/components/common/country-name';
import CpFundingStatus from '../shared/funding-status';
import { tableColumns } from '../options';
import CpEllipsis from '~/components/common/ellipse';
import OutTable from '../shared/out-table';

import {
  statuses,
  subscriptionStatusLabels,
  investStatusLabels,
} from '../../../fundraise/components/options';
import { kycStatus } from '../../../onboarding/components/options';

import { registrationStatus, investorTypeList } from '../../../holders/components/options';

export default {
  name: 'CpOutreachTable',
  components: {
    CpCountryName,
    CpFundingStatus,
    CpEllipsis,
    OutTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    searchFilter: {
      type: String,
      default: '',
    },
    stage: {
      type: String,
      default: 'onboarding',
    },
    totalItems: {
      type: Number,
      default: 10,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statuses,
      investStatusLabels,
      kycStatus,
      subscriptionStatusLabels,
      registrationStatus,
      queryParams: {
        page: 0,
        limit: parseInt(Cookie.get('perPage')) || 15,
        'order-direction': 'desc',
      },
    };
  },
  computed: {
    ...mapGetters('rounds', ['rounds']),
    tableFields() {
      return tableColumns[this.stage];
    },
  },
  methods: {
    getInvestorType(type) {
      return this.$t(investorTypeList[type]);
    },
    isLabels(item) {
      return (item || []).length && item.filter(val => val).length;
    },
    getLabelsList(item) {
      return (item || []).filter(val => val).slice(0, 3);
    },
    getLabelsTitle(item) {
      return (item || []).filter(val => val).slice(3).join('\n');
    },
    isRounds(rounds) {
      return (rounds || []).length && rounds.filter(val => val).length;
    },
    getRoundsList(rounds) {
      return (rounds || []).filter(val => val).slice(0, 3);
    },
    findNameById(id) {
      const round = this.rounds.find(item => item.id === +id);

      return round ? round.name : false;
    },
    getRoundsTitle(rounds) {
      return (rounds || []).filter(val => val).slice(3).map(val => this.findNameById(val)).join('\n');
    },
    investStatusData(item) {
      const colors = {
        'N\\A': 'secondary',
        ready: 'success',
      };
      return {
        status: this.$t(this.investStatusLabels[item]),
        color: colors[item] || 'warning',
      };
    },
  },
};
</script>
