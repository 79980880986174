export const registrationStatus = {
  styles: {
    none: 'secondary',
    pending: 'warning',
    syncing: 'primary',
    ready: 'success',
  },
  labels: {
    none: 'holders.status.walletRegistration.none',
    pending: 'holders.status.walletRegistration.pending',
    syncing: 'holders.status.walletRegistration.syncing',
    ready: 'holders.status.walletRegistration.ready',
    rejected: 'holders.status.walletRegistration.rejected',
    cancelled: 'holders.status.walletRegistration.cancelled',
  },
};

export const investorTypeList = {
  individual: 'common.category.investorType.individual',
  entity: 'common.category.investorType.entity',
  'fbo-individual': 'common.category.investorType.fboIndividual',
  'fbo-entity': 'common.category.investorType.fboEntity',
  omnibus: 'common.category.investorType.omnibus',
  'custodian-wallet-manager': 'common.category.investorType.custodian',
};

export const filterLists = {
  investorType: [
    { i18nKey: 'common.label.all', value: null },
    { i18nKey: 'common.category.investorType.individual', value: 'individual' },
    { i18nKey: 'common.category.investorType.entity', value: 'entity' },
    { i18nKey: 'common.category.investorType.fboIndividual', value: 'fbo-individual' },
    { i18nKey: 'common.category.investorType.fboEntity', value: 'fbo-entity' },
    { i18nKey: 'common.category.investorType.custodian', value: 'custodian-wallet-manager' },
    { i18nKey: 'common.category.investorType.omnibus', value: 'omnibus' },
  ],
};

export const getTableColumns = (type) => {
  if (type === 'custodian-management') {
    return [
      { key: 'checkbox', label: ' ', tdClass: 'text-nowrap align-middle' },
      { key: 'name', i18nKey: 'holders.label.table.name' },
      { key: 'friendlyName', i18nKey: 'holders.label.table.friendlyName' },
      { key: 'email', i18nKey: 'holders.label.table.email' },
      { key: 'custodianManagementActions', label: ' ', tdClass: 'text-nowrap d-inline float-right' },
    ];
  }
  return [
    { key: 'checkbox', label: ' ', tdClass: 'text-nowrap align-middle' },
    {
      key: 'name',
      i18nKey: type === 'beneficial-owners' ? 'holders.label.table.beneficialOwners' : 'holders.label.table.recordHolder',
      sortable: true,
      tdClass: 'align-middle',
    },
    {
      key: 'countryCode', i18nKey: 'holders.label.table.country', sortable: true, tdClass: 'align-middle',
    },
    {
      key: 'investorType', i18nKey: 'holders.label.table.investorType', sortable: true, tdClass: 'align-middle',
    },
    {
      key: 'totalTokens', i18nKey: 'holders.label.table.totalTokens', sortable: true, tdClass: 'align-middle',
    },
    { key: 'percentage', i18nKey: 'holders.label.table.percentage', tdClass: 'align-middle' },
    {
      key: 'walletAddress', i18nKey: 'holders.label.table.walletAddress', sortable: true, tdClass: 'align-middle',
    },
    {
      key: 'walletRegistrationStatus', i18nKey: 'holders.label.table.walletRegistrationStatus', sortable: true, tdClass: 'align-middle',
    },
    { key: 'labels', i18nKey: 'holders.label.table.labels', tdClass: 'text-nowrap align-middle text-center' },
    { key: 'actions', label: ' ', tdClass: 'text-nowrap d-inline float-right' },
  ];
};
