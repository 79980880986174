<template>
  <b-row>
    <b-col
      md="0"
      class="pl-2 mb-4"
    >
      <img
        v-if="tooltipText != ''"
        v-b-tooltip.hover.top="tooltipText"
        :src="tooltip"
        class="mail-icon mb-4"
      >
    </b-col>
    <b-col class="pl-1">
      <label
        v-if="showLabel"
        class="form-label"
      >
        {{ label }}
      </label>
      <b-input-group :append="append">
        <b-input
          v-validate.initial="validate"
          class="input-bordesless"
          :class="[ inputClass, {'password-input': inputType==='password'}, { 'border border-danger': error || controlledError, 'readonly': isReadonly }]"
          :disabled="disabled"
          :value="currentValue"
          :placeholder="displayedPlaceholder"
          :name="name"
          v-bind="htmlProps"
          :type="convertedType"
          :data-vv-as="validationFieldName"
          :readonly="isReadonly"
          @input="onChangeInput"
          @paste="$emit('paste',$event)"
          @keyup.enter="$emit('enter',$event)"
        />
        <b-input-group-prepend
          v-if="inputType==='password'"
          @click="showPassword()"
        >
          <span class="input-group-text">
            <i :class="['ion', isShow ? 'ion-md-eye' : 'ion-md-eye-off' ]" />
          </span>
        </b-input-group-prepend>
      </b-input-group>
      <span
        v-if="error || controlledError"
        class="invalid-feedback d-block"
      >
        {{ error || controlledError }}
      </span>
    </b-col>
  </b-row>
</template>
<script>
import tooltip from '~/assets/img/outreach/tooltip.svg';


export default {
  name: 'OutInput',
  props: {
    error: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    htmlProps: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    inputClass: {
      type: [String, Object, Array],
      default: 'form-group',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonlyPlaceholder: {
      type: String,
      default: '-',
    },
    validate: {
      type: String,
      default: '',
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    append: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tooltip,
      controlledError: '',
      controlledValue: this.value,
      touched: false,
      isShow: false,
    };
  },
  computed: {
    displayedPlaceholder() {
      return this.isReadonly ? this.readonlyPlaceholder : this.placeholder;
    },
    currentValue() {
      return this.value !== null ? this.value : this.controlledValue;
    },
    validationFieldName() {
      return this.label.toLowerCase() || this.name.split(/(?=[A-Z])/).join(' ').toLowerCase();
    },
    showLabel() {
      return this.label && !this.hideLabel;
    },
    convertedType() {
      return this.isShow && this.inputType === 'password' ? 'text' : this.inputType;
    },
  },
  methods: {
    onChangeInput(val) {
      if (this.inputType === 'number') {
        val = (this.touched || val !== '') ? Number(val) : null;
        this.touched = true;
      }
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss"  scoped>
.mail-icon {
  width: 13px;
  display: block;
}
.border-danger{
  border: 0 !important;
  border-bottom: 1px solid red !important;;

}
.input-bordesless {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #9b9b9b;
  border-radius: 0px;
  outline: 0;

  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: grey;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

</style>
