export default {
  data() {
    return {
      errorFields: [],
      // eslint-disable-next-line
      reg: new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,24}))$'),
      onlyNumbers: new RegExp('^[0-9]+$'),
    };
  },
  methods: {
    validationMandatoryFields(fieldsList, dataObj, email, birthdate) {
      fieldsList.forEach((el) => {
        dataObj[el] = dataObj[el] || '';
      });
      this.errorFields = fieldsList.filter(el => String(dataObj[el]).trim() === '');

      if (email) {
        const checkEmailField = this.reg.test(dataObj[email]);
        if (!checkEmailField) {
          this.errorFields.push(email);
        }
      }

      if (birthdate) {
        const birthdayTime = new Date(dataObj[birthdate]).getTime();
        if (dataObj[birthdate] && new Date().getTime() < birthdayTime) {
          this.errorFields.push('birthdate');
        } else {
          this.errorFields = this.errorFields.filter(key => key !== birthdate);
        }
      }

      return !this.errorFields.length;
    },
    validateNumbersOnly(fieldsList, dataObj, notReqFields) {
      fieldsList.forEach(item => (!this.onlyNumbers.test(dataObj[item]) ? this.errorFields.push(item) : null));
      if (notReqFields) {
        notReqFields.forEach((field) => {
          if (dataObj[field] === '') {
            this.errorFields = this.errorFields.filter(error => error !== field);
          }
        });
      }
    },
  },
};
