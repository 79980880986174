<template>
  <span>
    {{ status }}
  </span>
</template>

<script>
import getFundingStatus from '~/utilities/funding-status';

export default {
  name: 'CpFundingStatus',
  props: {
    amountFunded: {
      type: Number,
      default: 0,
      required: false,
    },
    amountPledged: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  computed: {
    status() {
      return getFundingStatus(this.amountFunded, this.amountPledged);
    },
  },
  getFundingStatus(amountFunded, amountPledged) {
    return getFundingStatus(amountFunded, amountPledged);
  },
};
</script>
