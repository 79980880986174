<template>
  <div class="chart">
    <b-spinner
      class="spinner"
    />
  </div>
</template>
<script>

export default {
  name: 'Spinner',
};
</script>

<style lang="scss" scoped>
  .chart {
    min-height: 473px;
  }
  .card-loader {
    min-height: 280px;
  }
  .spinner {
    margin: auto;
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
  }
</style>
