<template>
  <component-header
    :title="headerTitle"
    :collapse="collapseId"
    :svg="true"
  >
    <b-input-group
      class="mb-2 chk-group"
    >
      <b-input-group-prepend
        is-text
        class="pt-2 text-ellipsis"
      >
        <b-form-checkbox
          id="clearList"
          v-model="clear"
          variant="info"
          @input="onCheckboxChange('clearList')"
        />
        <label
          for="clearList"
          class="text-list"
        >
          {{ $t('outreach.label.filter.all') }}
        </label>
      </b-input-group-prepend>
    </b-input-group>
    <div class="list">
      <b-input-group
        v-for="(option, key) in selected"
        :key="key"
        class="mb-2 chk-group "
      >
        <b-input-group-prepend
          is-text
          class="text-ellipsis"
        >
          <b-form-checkbox
            :id="option.value.toString()"
            v-model="option.checked"
            button-variant="secondary"
            @input="onCheckboxChange(option.value)"
          />
          <label
            :for="option.value"
            class="text-list"
          > {{ option.text }} </label>
        </b-input-group-prepend>
        <template v-slot:append>
          <b-input-group-text
            @click="handleDeleteList(option.value)"
          >
            <i
              class="ion ion-md-trash"
              style="font-size:20px; cursor: pointer;"
            />
          </b-input-group-text>
        </template>
      </b-input-group>
    </div>
  </component-header>
</template>
<script>

import { mapActions } from 'vuex';
import ComponentHeader from '../shared/component-header';

export default {
  name: 'OutreachSavedContactLists',
  components: {
    ComponentHeader,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    clearDefault: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      headerTitle: this.$t('outreach.contact.savedContactListTitle'),
      collapseId: 'list',
      selected: [],
      clear: this.clearDefault,
    };
  },
  computed: {
    selection() {
      return this.selected.filter(checkbox => checkbox.checked).map(list => list.value);
    },
  },
  watch: {
    data(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selected = [];
        this.setCheckbox();
      }
    },
  },
  created() {
    this.setCheckbox();
  },
  methods: {
    ...mapActions('outreach', ['deleteContactList', 'saveFilterListState']),
    setCheckbox() {
      this.data.forEach((item) => {
        this.selected.push({
          value: item.value,
          text: item.text,
          checked: false,
        });
      });
    },
    onCheckboxChange(id) {
      if (id === 'clearList' && this.clear) {
        this.selected.forEach((element) => {
          element.checked = false;
        });
        this.clearStore();
        this.$emit('onUpdate', this.selection, true);
      } else {
        this.clear = false;
        this.$emit('onUpdate', this.selection);
      }
    },
    clearStore() {
      this.clear = true;
      this.saveFilterListState({ filterData: [] });
    },
    handleDeleteList(listId) {
      this.deleteContactList({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, listId });
      this.$emit('onDelete', listId);
    },
  },
};
</script>
<style scoped>
  .contact-list {
    font-weight: 500;
  }
  .input-group {
  justify-content: space-between;
  border: 0;
}
.input-group-text {
  border: 0;
  padding: 0px;
  flex: 1 1 33%;
  width: 25%;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
}
.text-ellipsis{
  display: flex;
  flex-direction: row;
  width: 85%;
}
.text-list {
  text-align: left;
  font-weight: 400;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 2 auto;
}
  .list {
    max-height: 220px;
    overflow-y: scroll;
  }
.chk-group {
  border-bottom: 1px solid #0a172715;
}
.chk-group:last-child {
  border-bottom: none;
}
.ion{
  margin-bottom: 8px;
  margin-right: 16px;
}
.custom-control{
  padding-left: 1.8rem;
}
</style>
