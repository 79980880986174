<template>
  <div class="content">
    <cp-loading-modal
      ref="viewSendedModal"
      :message="message"
      :is-loading="isLoading"
      :modal-icon="modalIcon"
    />

    <div class="column sidebar">
      <cp-outreach-stage
        @onUpdate="updateStage"
      />
      <cp-additional-contacts
        @onUpdate="saveCustomEmail"
      />
      <cp-outreach-saved-contact-lists
        ref="outreachSavedContactLists"
        :data="savedContactListNames"
        :clear-default="allInvestors"
        @onUpdate="getIssuerSavedContactListById"
      />
    </div>
    <div class="column body">
      <cp-email-form
        :domain="savedDomain"
        :is-authenticated="savedIsAuthenticated"
        @onSend="callSendEmail"
        @onTry="callTryEmail"
      />
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import CpOutreachSavedContactLists from '../contacts/lists';
import CpOutreachStage from '../contacts/stage';
import CpEmailForm from './email-form';
import CpAdditionalContacts from './additional-contacts';
import CpLoadingModal from '../shared/loading-modal';
import cleanEmptyContent from '~/utilities/clean';

export default {
  name: 'OutreachEmails',
  components: {
    CpOutreachSavedContactLists,
    CpOutreachStage,
    CpEmailForm,
    CpAdditionalContacts,
    CpLoadingModal,
  },
  data() {
    return {
      isLoading: false,
      stage: 'onboarding',
      isBusy: false,
      contactListIds: null,
      message: '',
      modalIcon: 'ion-md-checkmark-circle',
      allInvestors: false,
    };
  },
  computed: {
    ...mapGetters('outreach', ['getPreviewContactList', 'getSavedContactListNames', 'getInvestorsOutreachList', 'getTotalItems', 'getCustomEmails', 'getSavedDomain', 'getIsAuthenticated']),
    savedContactListNames() {
      return this.getSavedContactListNames();
    },
    savedDomain() {
      return this.getSavedDomain;
    },
    savedIsAuthenticated() {
      return this.getIsAuthenticated;
    },
  },
  beforeMount() {
    Promise.allSettled([this.getIssuerSavedContactLists()]);
  },
  methods: {
    ...mapActions('outreach', ['contactLists', 'sendEmail', 'clearSavedContactList']),

    updateStage(val) {
      this.isBusy = true;
      this.stage = val;
      this.clearSavedContactList();
      this.getIssuerSavedContactLists();
      this.clearAll();
    },
    getIssuerSavedContactLists() {
      return this.contactLists({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, stage: this.stage, round: 0 });
    },
    getIssuerSavedContactListById(ids, all) {
      this.contactListIds = ids;
      this.allInvestors = all;
    },
    clearAll() {
    },
    saveCustomEmail() {
      this.isBusy = true;
    },
    callTryEmail(content) {
      const that = this;
      this.message = this.$t('outreach.email.index.messageTry');
      content.additionalEmails = [];
      if (!this.duplicatesEmails(content)) {
        this.isLoading = true;
        this.message = this.$t('outreach.email.messages.processing');
        this.$refs.viewSendedModal.$refs.viewSendedModal.show();
        const cleanedContent = cleanEmptyContent(content);
        this.sendEmail({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, emailContent: cleanedContent, type: 'try' })
          .then(() => {
            that.message = that.$t('outreach.email.index.messageSend');
            that.modalIcon = 'ion-md-checkmark-circle';
            that.isLoading = false;
          })
          .catch(() => {
            that.message = 'Error';
            that.modalIcon = 'ion-md-close-circle';
            that.isLoading = false;
          });
      }
    },
    callSendEmail(content) {
      const that = this;
      content.additionalEmails = this.getCustomEmails || [];
      content.contactListIds = this.contactListIds || [];
      content.email = undefined;
      if (this.allInvestors || content.contactListIds.length > 0 || content.additionalEmails.length > 0) {
        if (!this.duplicatesEmails(content)) {
          this.isLoading = true;
          this.message = this.$t('outreach.email.messages.processing');
          this.$refs.viewSendedModal.$refs.viewSendedModal.show();
          const cleanedContent = cleanEmptyContent(content);
          this.sendEmail({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, emailContent: cleanedContent, type: 'send', stage: this.stage, allInvestors: this.allInvestors })
            .then(() => {
              that.message = that.$t('outreach.email.index.messageSend');
              that.modalIcon = 'ion-md-checkmark-circle';
              that.isLoading = false;
            }).catch((err) => {
              if (err.response.data.error === 'NOTIFICATION_WITHOUT_INVESTORS') {
                this.message = this.$t('outreach.email.messages.noSelected');
              } else {
                that.message = 'Error';
              }
              that.modalIcon = 'ion-md-close-circle';
              that.isLoading = false;
            });
        }
      } else {
        this.message = this.$t('outreach.email.messages.noSelected');
        this.modalIcon = 'ion-md-close-circle';
        this.$refs.viewSendedModal.$refs.viewSendedModal.show();
      }
    },
    duplicatesEmails(content) {
      const allEmails = [];
      const hasDuplicates = arr => new Set(arr).size !== arr.length;
      let duplicates = true;
      allEmails.push(content.email, ...content.cc, ...content.bcc, ...content.additionalEmails);
      duplicates = hasDuplicates(allEmails);
      if (duplicates) {
        this.message = this.$t('outreach.email.messages.duplicates');
        this.modalIcon = 'ion-md-close-circle';
        this.$refs.viewSendedModal.$refs.viewSendedModal.show();
      }
      return duplicates;
    },
  },
};

</script>

<style lang="scss" scoped>

.ion {
  color: #04bec4;
  font-size: 4rem;
}

.modal-size {
  width: 200px;
}

.content:after {
  content: "";
  display: table;
  clear: both;
}
.column {
  float: left;
  padding: 10px;
}

.card-body {
  padding: 0 10px;
}
.border{
    border-top: 0px !important;
  }
.sidebar {
  width: 25%
}

.body {
  width: 75%;
}
.card-header{
  padding: 0px;
  border-bottom: 1px solid #0a172715;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100% !important;
  }
}
</style>
