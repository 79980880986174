export default {
  methods: {
    tableSorting({ sortBy, sortDesc }) {
      if (!sortBy) return;

      const adaptName = sortBy.split(/(?=[A-Z])/).join('-').toLowerCase();

      // sortFields for custom names. example: { 'created-at': 'create-date',}
      const fieldName = (this.sortFields || {})[adaptName] || adaptName;
      const directionVal = sortDesc ? 'asc' : 'desc';

      return {
        'order-field': fieldName,
        'order-direction': directionVal,
      };
    },
  },
};
