<template>
  <div
    align="center"
    body-class="card-content"
  >
    <cp-outreach-domain-instructions class="mb-5" />
    <b-card
      bg-variant="dark"
      text-variant="white"
      class="card-domain"
      align="center"
    >
      <div v-if="!isLoading">
        <b-card-title>
          {{ $t('outreach.dashboard.pendingStatus.pendingTitle') }}
        </b-card-title>
        <img
          :src="PendingIcon"
          class="mt-2 mb-3"
        >
        <b-card-text>
          <h5 style="break-word=">
            {{ $t('outreach.dashboard.pendingStatus.pendingMessage') }}
          </h5>
        </b-card-text>
      </div>
      <b-spinner
        v-if="isLoading"
        class="spinner"
      />
    </b-card>
  </div>
</template>
<script>
import PendingIcon from '~/assets/img/Pending.svg';
import CpOutreachDomainInstructions from './instructions';

export default {
  name: 'OutreachDomainPendingStatus',
  components: {
    CpOutreachDomainInstructions,
  },
  props: {
    isLoading: {
      type: Boolean,
      requiered: true,
    },
  },
  data() {
    return {
      PendingIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
  .card-body {
    min-height: 217px;
    padding: 0.7rem;
  }
  .spinner {
    margin: auto;
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
  }
</style>
