<template>
  <div class="chart">
    <div v-if="!isLoading">
      <div class="pt-0 mb-5">
        <div class="row no-gutters  row-border-light h-100 align-items-center">
          <div class="col-md-12">
            <span class=" float-right">{{ $t('outreach.dashboard.chart.days') }}</span>
          </div>
          <b-card-group
            align="center"
            class="mx-auto"
            deck
          >
            <card-info
              :text="$t('outreach.dashboard.chart.sent')"
              :value="totalSent"
              theme="sent"
            />
            <card-info
              :text="$t('outreach.dashboard.chart.opened')"
              :value="totalOpen"
              theme="opened"
            />
            <card-info
              :text="$t('outreach.dashboard.chart.click')"
              :value="totalClick"
              theme="click"
            />
          </b-card-group>
        </div>
      </div>
      <b-row>
        <b-col>
          <cp-chart :options="splineOptions" />
        </b-col>
      </b-row>
    </div>
    <b-spinner
      v-if="isLoading"
      class="spinner"
    />
  </div>
</template>
<script>
import CpChart from '~/components/common/statistic';
import CardInfo from './card-info';

const chartColors = ['#006A88', '#0A8FB8', '#DD7802', '#3287c6', '#1f537a'];


export default {
  name: 'OutreachChart',
  components: {
    CpChart,
    CardInfo,
  },
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isDataReady: false,
      splineOptions: {
        color: {
          pattern: chartColors,
        },
        data: {
          x: 'x',
          columns: [],
          type: 'line',
        },
        axis: {
          x: {
            type: 'timeseries',
          },
          y: {
            padding: {
              top: 2,
              bottom: 3,
            },
            tick: {},
          },
        },
        transition: {
          duration: 500,
        },
      },
    };
  },
  computed: {
    totalSent() {
      const index = this.chartData.map(e => e.type).indexOf('sent');
      return this.chartData[index].data.reduce((prev, cur) => prev + cur.value, 0);
    },
    totalOpen() {
      const index = this.chartData.map(e => e.type).indexOf('open');
      return this.chartData[index].data.reduce((prev, cur) => prev + cur.value, 0);
    },
    totalClick() {
      const index = this.chartData.map(e => e.type).indexOf('click');
      return this.chartData[index].data.reduce((prev, cur) => prev + cur.value, 0);
    },
    dateAxis() {
      const index = this.chartData.map(e => e.type).indexOf('sent');
      const parsed = this.chartData[index].data.map(item => item.date);
      parsed.unshift('x');
      return parsed;
    },
    parsedSent() {
      const index = this.chartData.map(e => e.type).indexOf('sent');
      return this.parseToChartOptions(this.chartData[index].data, this.$t('outreach.dashboard.chart.sent'));
    },
    parsedOpened() {
      const index = this.chartData.map(e => e.type).indexOf('open');
      return this.parseToChartOptions(this.chartData[index].data, this.$t('outreach.dashboard.chart.opened'));
    },
    parsedClick() {
      const index = this.chartData.map(e => e.type).indexOf('click');
      return this.parseToChartOptions(this.chartData[index].data, this.$t('outreach.dashboard.chart.click'));
    },
  },
  async beforeMount() {
    await this.updateOnboardingInfo();
  },
  methods: {
    async updateOnboardingInfo() {
      this.isDataReady = false;
      this.splineOptions.data.columns = [this.dateAxis, this.parsedSent, this.parsedOpened, this.parsedClick];
      // set Y axis ticks
      const yTicks = this.getYTicks(
        [...this.parsedSent.slice(1), ...this.parsedOpened.slice(1), ...this.parsedClick.slice(1)],
      );

      if (yTicks[yTicks.length - 1] >= 20) {
        this.splineOptions.axis.y.ticks = 20;
      } else {
        this.splineOptions.axis.y.tick.values = yTicks;
      }

      this.isDataReady = true;
    },
    parseToChartOptions(list, title) {
      const parsed = list.map(item => item.value);
      parsed.unshift(title);
      return parsed;
    },
    getYTicks(list) {
      const max = list.reduce(
        (dist, val) => (val > dist ? val : dist),
        0,
      );
      return new Array(max).fill(0).map((val, index) => index + 1);
    },

  },
};
</script>

<style lang="scss" scoped>
  .card-loader {
    min-height: 280px;
  }
  .spinner {
    margin: auto;
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
  }
</style>
