<template>
  <component-header
    :title="$t('outreach.email.additionalContacts.title')"
    :collapse="collapseId"
    icon="ion-md-mail"
  >
    <cp-input-container
      ref="cpOutreachAdditionalContacts"
    >
      <b-row class="div-add mb-2">
        <b-col md="12">
          <cp-input
            v-model="email"
            v-validate="'email'"
            name="email"
            input-class=""
            class="mt-2 mb-2"
            :placeholder="$t('outreach.email.form.example')"
          />
        </b-col>
        <div class="mx-auto mb-3">
          <b-button
            pill
            variant="outline-info"
            class="mx-auto save-btn"
            @click="addCustomEmail"
          >
            {{ $t('outreach.email.additionalContacts.add') }}
          </b-button>
        </div>
      </b-row>
    </cp-input-container>
    <div class="list">
      <b-input-group
        v-for="(item, key) in customEmails"
        :key="key"
        class="mb-2 chk-group"
      >
        <b-input-group-prepend
          class="text-ellipsis"
          is-text
        >
          <label
            :for="item"
            class="text-list pl-3"
          > {{ item }} </label>
        </b-input-group-prepend>
        <template v-slot:append>
          <b-input-group-text
            @click="handleDeleteList(item)"
          >
            <i
              class="ion ion-md-trash"
              style="font-size:20px; cursor: pointer;"
            />
          </b-input-group-text>
        </template>
      </b-input-group>
    </div>
  </component-header>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import ComponentHeader from '../shared/component-header';
import { stageList } from '../options';
import { CpInput } from '~/components/common/standalone-components/inputs';
import CpInputContainer from '~/components/common/cpInputContainer';


export default {
  name: 'OutreachAdditionalContacts',
  components: {
    ComponentHeader,
    CpInputContainer,
    CpInput,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headerTitle: 'Investment Stage',
      collapseId: 'additionalContacts',
      selected: 'onboarding',
      options: stageList,
      customEmails: [],
      email: '',
    };
  },
  computed: {
    ...mapGetters('outreach', ['getCustomEmails']),
  },
  created() {
    this.customEmails = this.getCustomEmails;
  },
  methods: {
    ...mapActions('outreach', ['addCustomEmailState', 'setCustomEmailState']),

    addCustomEmail() {
      const { email } = this;
      this.$refs.cpOutreachAdditionalContacts.validateForm().then(() => {
        if (this.$refs.cpOutreachAdditionalContacts.isValid() && this.email !== '') {
          this.addCustomEmailState({ email });
          this.email = '';
        }
      });
    },
    handleDeleteList(email) {
      this.setCustomEmailState({ email });
      this.customEmails = this.getCustomEmails;
      this.$emit('onDelete', email);
    },
  },
};
</script>
<style scoped>
  .contact-list {
    font-weight: 500;
  }
  .input-group {
  justify-content: space-between;
  border: 0;
}
.input-group-text {
  border: 0;
  padding: 0px;
  flex: 1 1 33%;
  width: 25%;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
}
.text-ellipsis{
  display: flex;
  flex-direction: row;
  width: 79%;
}
.text-list {
  text-align: left;
  font-weight: 400;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 2 auto;
}
.list {
  max-height: 160px;
  overflow-y: scroll;
}
.check {
  margin-right: 15px;
  margin-bottom: 8px;
  padding: 10px;
}
.chk-group {
  border-bottom: 1px solid #0a172715;
}
.chk-group:last-child {
  border-bottom: none;
}
.ion{
  margin-bottom: 8px;
  margin-right: 16px;
}

input:valid {
  border-color: green;
}

input:invalid {
  border-color: red;
}

.save-btn {
    min-width: 110px;
    font-weight: 600;
    height: 1.8rem;
    padding-top: 1px;
    border-width: 0.124rem;
    width: 9rem;
  }
.div-add {
  border-bottom: 1px solid #0a172715;
}

</style>
