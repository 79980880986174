<template>
  <div>
    <div class="header-block mb-4">
      <b-row>
        <b-col>
          <h4 class="font-weight-bold mb-0">
            <img
              :src="EmailIcon"
              width="20"
              height="20"
            >
            {{ $t('outreach.prependTitle') }}
            <span class="text-muted font-weight-normal">
              | {{ title }}
            </span>
          </h4>
        </b-col>
        <div v-if="domain != ''">
          <b-col>
            <cp-button
              variant="info"
              class="pt-1 pb-1 mr-2 float-right"
              size="md"
              @click="resetDomain"
            >
              {{ $t('outreach.resetDomainButton') }}
            </cp-button>
          </b-col>
          <b-col md="12">
            <div class="float-right mt-1 pr-2">
              <span class="">
                {{ domain }}
              </span>
              <i
                v-if="isAuthenticated"
                class="filter-ico ion ion-ios-checkmark-circle"
              />
            </div>
          </b-col>
        </div>
      </b-row>
    </div>
  </div>
</template>
<script>
import EmailIcon from '~/assets/img/Email.svg';
import CpButton from '~/components/common/standalone-components/cp-button';

export default {
  name: 'CpOutreachHead',
  components: {
    CpButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
    isAuthenticated: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      EmailIcon,
    };
  },
  methods: {
    resetDomain() {
      this.$emit('onDelete');
    },
  },
};
</script>

<style scope>
.ion-ios-checkmark-circle {
  color: #04bec4;
}
</style>
