import { render, staticRenderFns } from "./file-uploader.vue?vue&type=template&id=1c892b41&"
import script from "./file-uploader.vue?vue&type=script&lang=js&"
export * from "./file-uploader.vue?vue&type=script&lang=js&"
import style0 from "~/../node_modules/vue2-dropzone/dist/vue2Dropzone.min.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./file-uploader.vue?vue&type=style&index=1&id=1c892b41&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports