<template>
  <b-card
    :border-variant="theme"
    no-body
    no-wrap
  >
    <b-card-text class="ml-4 mr-4">
      <small class="no-wrap">
        {{ text }}
      </small><br>
      <span class="no-wrap text-big font-weight-bolder">
        <cp-formatted-number :value="value" />
      </span>
    </b-card-text>
  </b-card>
</template>

<script>
import CpFormattedNumber from '~/components/common/filters/number-formatter';


export default {
  name: 'CardInfo',
  components: {
    CpFormattedNumber,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    theme: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>

  .no-wrap{
    white-space: nowrap
  }
  .border-opened{
    border-color: #0A8FB8 !important;
  }
  .border-click{
    border-color: #DD7802 !important;
  }
  .border-sent{
    border-color: #006A88 !important;
  }
  .card{
    border-width: 1.8px;
  }

</style>
