var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('out-table',{attrs:{"items":_vm.data,"fields":_vm.tableFields,"busy":_vm.tableLoading,"query-params":_vm.queryParams,"total-items":_vm.totalItems},on:{"onPagination":function($event){return _vm.$emit('onPagination', $event)}},scopedSlots:_vm._u([{key:"countryCode",fn:function(ref){
var rowData = ref.rowData;
return [_c('cp-country-name',{attrs:{"country-code":rowData.value}})]}},{key:"investorType",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.getInvestorType(rowData.value))+" ")]}},{key:"fundingStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('cp-funding-status',{attrs:{"amount-funded":rowData.item.amountFunded,"amount-pledged":rowData.item.amountPledged}})]}},{key:"kycStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":_vm.kycStatus.styles[rowData.value]}},[_vm._v(" "+_vm._s(_vm.$t(_vm.kycStatus.labels[rowData.value]) || '')+" ")])]}},{key:"investorStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer"},attrs:{"variant":_vm.investStatusData(rowData.value).color}},[_vm._v(" "+_vm._s(_vm.investStatusData(rowData.value).status)+" ")])]}},{key:"subscriptionAgreementStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":_vm.statuses.subscription[rowData.value]}},[_vm._v(" "+_vm._s(_vm.$t(_vm.subscriptionStatusLabels[rowData.value]))+" ")])]}},{key:"roundIds",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.isRounds(rowData.value))?_c('div',[_vm._l((_vm.getRoundsList(rowData.value)),function(id){return _c('span',{key:id},[( _vm.findNameById(id) != false)?_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.findNameById(id))+" ")]):_vm._e()],1)}),(rowData.value.length > 3)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer"},attrs:{"title":_vm.getRoundsTitle(rowData.value)}},[_vm._v(" + "+_vm._s(rowData.value.length - 3)+" ")]):_vm._e()],2):_vm._e()]}},{key:"percentage",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"no-break"},[_c('cp-ellipsis',{staticClass:"no-break",attrs:{"data-value":rowData.value}},[_vm._v(" % ")])],1)]}},{key:"walletStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":_vm.registrationStatus.styles[rowData.value]}},[_vm._v(" "+_vm._s(_vm.$t(_vm.registrationStatus.labels[rowData.value]))+" ")])]}},{key:"labels",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.isLabels(rowData.value))?_c('div',[_vm._l((_vm.getLabelsList(rowData.value)),function(label){return _c('span',{key:label},[(label)?_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(label)+" ")]):_vm._e()],1)}),(rowData.value.length > 3)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer"},attrs:{"title":_vm.getLabelsTitle(rowData.value)}},[_vm._v(" + "+_vm._s(rowData.value.length - 3)+" ")]):_vm._e()],2):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }