<template>
  <component-header
    :title="headerTitle"
    :collapse="collapseId"
  >
    <cp-select
      v-model="selected"
      class="pt-2"
      name="stage"
      input-class="mb-2"
      :options="options"
      @input="onStageChange"
    />
  </component-header>
</template>
<script>
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import ComponentHeader from '../shared/component-header';
import { stageList } from '../options';

export default {
  name: 'OutreachStage',
  components: {
    CpSelect,
    ComponentHeader,
  },
  data() {
    return {
      headerTitle: this.$t('outreach.contact.investmentTitle'),
      collapseId: 'stage',
      selected: 'onboarding',
      options: stageList,
    };
  },
  methods: {
    onStageChange() {
      this.$emit('onUpdate', this.selected);
    },
  },
};
</script>
