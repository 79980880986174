<template>
  <div
    align="center"
    body-class="card-content"
  >
    <cp-outreach-domain-instructions class="mb-5" />
    <b-card
      bg-variant="dark"
      text-variant="white"
      class="card-domain"
      align="left"
    >
      <div v-if="!isLoading">
        <cp-input-container
          ref="cpOutreachFormContainer"
        >
          <b-row>
            <b-col md="12">
              <out-input
                v-model="form.name"
                name="domain"
                :tooltip-text="$t('outreach.dashboard.label.toolDomain')"
                :label="$t('outreach.dashboard.label.domain')"
                validate="required|domain"
                placeholder="example.com"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="6">
              <out-input
                v-model="form.pointOfContactEmail"
                name="email"
                :tooltip-text="$t('outreach.dashboard.label.toolToEmail')"
                :label="$t('outreach.dashboard.label.toEmail')"
                validate="required|email"
                placeholder="ITdept@example.com"
              />
            </b-col>
            <b-col md="6">
              <out-input
                v-model="form.ccEmail"
                name="ccedEmail"
                :tooltip-text="$t('outreach.dashboard.label.toolCcedEmail')"
                :label="$t('outreach.dashboard.label.ccedEmail')"
                validate="multiple_email"
                placeholder="john@example.com"
              />
            </b-col>
          </b-row>
        </cp-input-container>
        <b-row
          align-v="center"
          align-h="center"
          class="mt-3"
        >
          <b-col md="2.5">
            <cp-button
              variant="info"
              size="md"
              class="submit"
              @click="handleSubmit"
            >
              {{ $t('outreach.dashboard.label.submitButton') }}
            </cp-button>
          </b-col>
        </b-row>
      </div>
      <b-spinner
        v-if="isLoading"
        class="spinner"
      />
    </b-card>
  </div>
</template>
<script>
import OutInput from '../shared/out-input';
import CpInputContainer from '~/components/common/cpInputContainer';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpOutreachDomainInstructions from './instructions';


export default {
  name: 'OutreachForm',
  components: {
    OutInput,
    CpInputContainer,
    CpButton,
    CpOutreachDomainInstructions,
  },
  props: {
    isLoading: {
      type: Boolean,
      requiered: true,
    },
  },
  data() {
    return {
      form: {
        name: '',
        pointOfContactEmail: '',
        ccEmail: '',
      },
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.cpOutreachFormContainer.validateForm().then(() => {
        if (this.$refs.cpOutreachFormContainer.isValid()) {
          this.$emit('submit', this.form);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .card-body {
    min-height: 200px;
    padding: 0.7rem 1.3rem 0.7rem 0.7rem,;
  }
  .mail-icon {
    font-size: 4em;
    margin-bottom: 15px;
  }
  .spinner {
    margin: auto;
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
  }
  .submit {
    width: 9rem;
    border-radius: 20px;
  }
</style>
