<template>
  <component-header
    :title="title"
    :collapse="collapseId"
    :svg="true"
  >
    <cp-input-container
      ref="cpOutreachContactListPanel"
    >
      <b-row class="contact-list-action-wrapper">
        <b-col md="12">
          <b-input
            v-model="contactListName"
            name="Contact name"
            class="mt-2 mb-2"
            placeholder="Create a contact list"
          />
        </b-col>
        <div class="mx-auto mb-2">
          <b-button
            pill
            variant="outline-info"
            :disabled="contactListName === ''"
            class="mx-auto save-btn"
            @click="handleSaveList"
          >
            {{ $t('outreach.contact.save') }}
          </b-button>
        </div>
      </b-row>
    </cp-input-container>
  </component-header>
</template>
<script>
import ComponentHeader from '../shared/component-header';
import CpInputContainer from '~/components/common/cpInputContainer';


export default {
  name: 'OutreachCreateContactList',
  components: {
    CpInputContainer,
    ComponentHeader,
  },
  props: {
    busy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapseId: 'table-panel',
      contactListName: '',
      searchFilter: '',
      title: this.$t('outreach.contact.newList'),
    };
  },
  watch: {
    searchFilter() {
      this.$emit('handleSearch', this.searchFilter);
    },
  },
  methods: {
    handleSaveList() {
      if (this.contactListName !== '') {
        this.$emit('onUpdate', this.contactListName);
        this.contactListName = '';
      }
    },
  },
};
</script>
<style scoped>
  .header-wrapper {
    display: flex;
    justify-content: normal;
    align-items: center;
    border-bottom: 0px;
  }

  .contact-list-action-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .header-padding{
    padding-top: 6px;
    padding-bottom: 5px;
  }
  .save-btn {
    min-width: 110px;
    font-weight: 600;
    height: 1.8rem;
    padding-top: 1px;
    border-width: 0.124rem;
    width: 9rem;
  }
</style>
