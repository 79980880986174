<template>
  <b-card
    class="mt-0"
    footer-class="m-0 p-2"
    body-class="m-0 p-2"
  >
    <cp-general-modal
      ref="viewTestEmailModal"
      ok-text="Send Test Email"
      :hide-header="true"
      @onOk="trySend"
    >
      <cp-input
        v-model="email"
        v-validate="'required|email'"
        name="email"
        label="Email Address"
        :placeholder="$t('outreach.email.form.example')"
      />
    </cp-general-modal>
    <cp-general-modal
      ref="cpConfirmModal"
      ok-text="Confirm"
      :hide-header="true"
      size="sm"
      @onOk="send"
    >
      {{ $t('outreach.email.form.confirmation') }}
    </cp-general-modal>
    <b-container
      fluid
      class="p-1"
    >
      <cp-input-container
        ref="cpOutreachEmailFormPanel"
        @error="error"
      >
        <b-row>
          <b-col md="6">
            <cp-input
              v-model="fromAddress"
              v-validate="{required: true, regex: /^([\w+-.%]{2,4},?)+$/ }"
              input-class="p-2"
              class="mt-2"
              :name="$t('outreach.email.form.fromAddress')"
              size="sm"
              :label="$t('outreach.email.form.fromAddress')"
              :append="domain"
            />
          </b-col>

          <b-col md="6">
            <cp-input
              v-model="emailForm.fromName"
              input-class="p-2"
              :name="$t('outreach.email.form.fromName')"
              class="mt-2"
              size="sm"
              :label="$t('outreach.email.form.fromName')"
              :placeholder="$t('outreach.email.form.required')"
              validate="required"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <cp-input
              v-model="emailForm.replyToAddress"
              input-class="p-2"
              class="mt-2"
              size="sm"
              :name="$t('outreach.email.form.replyToAddress')"
              :label="$t('outreach.email.form.replyToAddress')"
              :placeholder="$t('outreach.email.form.required')"
              validate="required|email"
            />
          </b-col>

          <b-col md="6">
            <cp-input
              v-model="emailForm.replyToName"
              input-class="p-2"
              class="mt-2"
              size="sm"
              :name="$t('outreach.email.form.replyToName')"
              :label="$t('outreach.email.form.replyToName')"
              :placeholder="$t('outreach.email.form.optional')"
              validate=""
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <cp-input
              v-model="cc"
              input-class="p-2"
              class="mt-2"
              size="sm"
              :name="$t('outreach.email.form.cc')"
              :label="$t('outreach.email.form.cc')"
              :placeholder="$t('outreach.email.form.optional')"
              validate="multiple_email"
            />
          </b-col>

          <b-col md="6">
            <cp-input
              v-model="bcc"
              input-class="p-2"
              class="mt-2"
              size="sm"
              :name="$t('outreach.email.form.bcc')"
              :label="$t('outreach.email.form.bcc')"
              :placeholder="$t('outreach.email.form.optional')"
              validate="multiple_email"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <cp-input
              v-model="emailForm.subject"
              input-class="p-2"
              class="mt-2"
              size="sm"
              :name="$t('outreach.email.form.subject')"
              :label="$t('outreach.email.form.subject')"
              :placeholder="$t('outreach.email.form.required')"
              validate="required"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <vue-editor
              id="editor"
              ref="myQuillEditor"
              v-model="emailForm.body"
              data-vv-name="body"
              input-class="p-2"
              class="mt-2"
              :custom-modules="customModulesForEditor"
              :editor-options="editorSettings"
              use-custom-image-handler
              validate="required"
              @image-added="handleImageAdded"
            />
            <b-button
              v-if="false"
              class="mt-1 btn btn-warning"
              @click="emailForm.attachments = null"
            >
              Delete Attachments
            </b-button>
          </b-col>
          <b-col md="12">
            <file-uploader
              id="emailFiles"
              ref="emailFiles"
              @onRemove="fileRemoved"
              @onChange="fileUploaded"
              @onComplete="fileUploadedComplete"
            />
          </b-col>
        </b-row>
      </cp-input-container>
    </b-container>
    <template v-slot:footer>
      <b-row class="float-right">
        <cp-button
          variant="outline-info"
          class="pt-1 pb-1 mr-2"
          size="md"
          :disabled="disabled || !isComplete || !filesUploaded"
          @click="test"
        >
          {{ $t('outreach.email.form.test') }}
        </cp-button>
        <cp-button
          variant="info"
          class="pt-1 pb-1 mr-3"
          size="md"
          :disabled="disabled || !isComplete || !filesUploaded"
          @click="confirm"
        >
          {{ $t('outreach.email.form.send') }}
        </cp-button>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import { VueEditor, Quill } from 'vue2-editor';
import { ImageDrop } from 'quill-image-drop-module';

import axios from '~/utilities/axios';
import { htmlEditButton } from '~/utilities/quill-modules/htmlEditButton';
import { CpInput } from '~/components/common/standalone-components/inputs';
import CpInputContainer from '~/components/common/cpInputContainer';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import FileUploader from './file-uploader';

const Link = Quill.import('formats/link');
Link.sanitize = (url) => {
  if (!url.includes('http')) {
    return `http://${url}`;
  }
  return url;
};

export default {
  components: {
    CpInput,
    CpButton,
    CpInputContainer,
    VueEditor,
    CpGeneralModal,
    FileUploader,
  },
  props: {
    domain: {
      type: String,
      required: true,
    },
    isAuthenticated: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      disabled: true,
      filesUploaded: true,
      cc: '',
      bcc: '',
      email: '',
      fromAddress: '',
      emailForm: {
        subject: '',
        body: '',
        fromAddress: '',
        fromName: '',
        replyToAddress: '',
        attachments: [],
        replyToName: '',
        cc: [],
        bcc: [],
      },
      customModulesForEditor: [
        { alias: 'imageDrop', module: ImageDrop },
        { alias: 'htmlEditButton', module: htmlEditButton },
      ],
      editorSettings: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ font: [] }],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ['clean'],
              ['link', 'image'],
              ['attachment'],
            ],
            handlers: {
              attachment: () => {
                this.$refs.emailFiles.$el.childNodes[0].click();
              },
            },
          },
          imageDrop: false,
          htmlEditButton: {},
        },
      },
    };
  },
  computed: {
    isComplete() {
      return this.isAuthenticated && this.emailForm.body && this.emailForm.subject && this.emailForm.replyToAddress && this.fromAddress && this.emailForm.fromName;
    },
  },
  watch: {
    cc(newVal, oldVal) {
      if (newVal !== oldVal) {
        const arrayVal = [...new Set(newVal.split(/,|;/))];
        this.cc = arrayVal.toString();
        this.emailForm.cc = arrayVal;
      }
    },
    bcc(newVal, oldVal) {
      if (newVal !== oldVal) {
        const arrayVal = [...new Set(newVal.split(/,|;/))];
        this.bcc = arrayVal.toString();
        this.emailForm.bcc = arrayVal;
      }
    },
  },
  methods: {
    fileUploaded(fileData) {
      if (fileData.fileStatus === 'added') {
        this.filesUploaded = false;
      } else {
        this.emailForm.attachments = [...this.emailForm.attachments, fileData];
      }
    },
    fileUploadedComplete() {
      this.filesUploaded = true;
    },
    fileRemoved(file) {
      const index = this.emailForm.attachments.findIndex(item => item.fileKey === file.fileKey);
      if (index !== -1) {
        this.emailForm.attachments.splice(index, 1);
      }
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      const CLIENT_ID = '0e66b4bdc161255';
      file.cid = 'myimagecid';
      const formData = new FormData();
      formData.append('image', file);

      axios({
        url: 'https://api.imgur.com/3/image',
        method: 'POST',
        headers: {
          Authorization: `Client-ID ${CLIENT_ID}`,
        },
        data: formData,
      })
        .then((result) => {
          const url = result.data.data.link; // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url);
          resetUploader();
        })
        .catch((err) => {
          this.$log.error('Error:', err);
        });
    },
    test() {
      this.$refs.viewTestEmailModal.show();
    },
    confirm() {
      this.$refs.cpConfirmModal.show();
    },
    trySend() {
      const { emailForm } = this;
      this.$refs.cpOutreachEmailFormPanel.validateForm().then(() => {
        if (this.$refs.cpOutreachEmailFormPanel.isValid()) {
          emailForm.email = this.email;
          emailForm.fromAddress = `${this.fromAddress}${this.domain}`;
          this.$emit('onTry', emailForm);
        } else {
          this.disabled = true;
        }
        this.$refs.viewTestEmailModal.hide();
      });
    },
    send() {
      this.$refs.cpConfirmModal.hide();
      const { emailForm } = this;
      this.$refs.cpOutreachEmailFormPanel.validateForm().then(() => {
        if (this.$refs.cpOutreachEmailFormPanel.isValid()) {
          emailForm.fromAddress = `${this.fromAddress}${this.domain}`;
          this.$emit('onSend', emailForm);
        } else {
          this.disabled = true;
        }
        this.$refs.viewTestEmailModal.hide();
      });
    },
    error(errors) {
      this.errors = errors;
      if (errors.length === 0) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
};

</script>

<style lang="scss">

.ql-html-overlayContainer {
  background: #0000007d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.ql-html-popupContainer {
  background: #ddd;
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 10%;
  border-radius: 10px;
}

.ql-html-textContainer {
  position: relative;
  width: calc(100% );
  height: calc(100%);
  padding-top: 20px;
}

.ql-html-textArea {
  position: absolute;
  left: 15px;
  width: calc(100% - 45px);
  height: calc(100% - 116px);
}

.ql-html-buttonCancel {
  margin-right: 20px;
}

.ql-html-popupTitle {
  margin: 0;
  display: block;
}

.ql-html-buttonGroup {
  position: absolute;
  bottom: 20px;
  left: calc(83%);
}

.ql-attachment:after {
  content: "\f28e";
  font-family: "Ionicons";
  font-size: 1.05rem;
}
</style>
