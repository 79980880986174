<template>
  <div class="content">
    <div class="column sidebar">
      <cp-outreach-stage
        @onUpdate="updateStage"
      />
      <cp-outreach-filter
        ref="outreachFilter"
        :stage="stage"
        @onUpdate="updateFilter"
        @onRoundChange="onRoundChange"
      />
      <cp-outreach-create-contact-list
        :busy="isBusy"
        @onUpdate="saveList"
        @handleSearch="handleSearch"
      />
      <cp-outreach-saved-contact-lists
        ref="outreachSavedContactLists"
        :data="savedContactListNames"
        @onUpdate="getIssuerSavedContactListById"
        @onDelete="deleteSavedContactList"
      />
    </div>
    <div class="column body">
      <b-card class="mt-0">
        <cp-outreach-table
          :stage="stage"
          :total-items="totalItems"
          :data="previewContactList"
          :search-filter="searchFilter"
          :table-loading="isBusy"
          @onPagination="setQueryParams"
        />
      </b-card>
    </div>
  </div>
</template>
<script>
import Cookie from 'js-cookie';
import { mapActions, mapGetters } from 'vuex';
import CpOutreachFilter from './filter';
import CpOutreachTable from './table';
import CpOutreachSavedContactLists from './lists';
import CpOutreachCreateContactList from './create-contact-list';
import CpOutreachStage from './stage';
import GeneralActionsOfInvestorPages from '~/mixins/general-actions-of-investor-pages';


export default {
  name: 'OutreachContacts',
  components: {
    CpOutreachFilter,
    CpOutreachTable,
    CpOutreachSavedContactLists,
    CpOutreachCreateContactList,
    CpOutreachStage,
  },
  mixins: [GeneralActionsOfInvestorPages],
  data() {
    return {
      tableRef: 'outreachTable',
      filterRef: 'outreachFilter',
      filterStoreName: 'investorsOutreach',
      listsRef: 'outreachSavedContactLists',
      selectedListsUuid: [],
      isBusy: true,
      searchFilter: '',
      filtersSelected: [],
      stage: 'onboarding',
      queryParams: {
        page: 0,
        limit: parseInt(Cookie.get('perPage')) || 15,
        'order-direction': 'desc',
      },
    };
  },
  computed: {
    ...mapGetters('outreach', ['getPreviewContactList', 'getSavedContactListNames', 'getSavedContactListById', 'getInvestorsOutreachList', 'getTotalItems']),
    ...mapGetters('global', ['getFilterState']),
    ...mapGetters('investors', [
      'getInvestorById',
      'getSorting',
      'labelsList',
    ]),
    investorsType() {
      return {
        issuerId: this.$route.params.idIssuer,
        view: 'fundraise',
        'round-id': this.selectedRound,
      };
    },
    getFilterValues() {
      return this.getFilterState(this.filterStoreName);
    },
    totalItems() {
      return this.getTotalItems;
    },
    previewContactList() {
      return this.getPreviewContactList();
    },
    savedContactListNames() {
      return this.getSavedContactListNames();
    },
    investorsOutreachList() {
      return this.getInvestorsOutreachList;
    },
    roundSelected() {
      return this.getRoundSelected;
    },
  },
  created() {
    Promise.allSettled([this.getIssuerContactList(), this.getIssuerSavedContactLists()]);
  },
  methods: {
    ...mapActions('outreach', ['viewContacts', 'contactLists', 'createContactList', 'saveFilterListState', 'clearSavedContactList']),
    ...mapActions('investors', [
      'getIssuerLabels',
    ]),
    getIssuerContactList() {
      return this.viewContacts({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, stage: this.stage, filters: this.filtersSelected, queryParams: this.queryParams })
        .then(() => this.disableLoad());
    },
    disableLoad() {
      this.isBusy = false;
    },
    enableLoad() {
      this.isBusy = true;
    },
    getIssuerSavedContactLists() {
      return this.contactLists({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, stage: this.stage, round: this.getFilterValues.rounds });
    },
    updateFilter(filter) {
      this.enableLoad();
      const savedFilters = [];
      Object.assign(savedFilters, this.investorsOutreachList);
      savedFilters.push(filter);
      this.filtersSelected = savedFilters;
      this.getIssuerContactList();
    },
    updateStage(val) {
      this.enableLoad();
      this.clearSavedContactList();
      this.stage = val;
      this.filtersSelected = [];
      this.getIssuerContactList()
        .then(() => this.getIssuerSavedContactLists());
      this.clearAll();
    },
    onRoundChange() {
      this.getIssuerSavedContactLists();
    },
    deleteSavedContactList() {
      this.clearAll();
    },
    saveList(name) {
      const filter = this.getFilterValues;
      if (filter || this.investorsOutreachList) {
        this.enableLoad();
        const savedFilters = [];
        Object.assign(savedFilters, this.investorsOutreachList);
        if (Object.keys(filter).length > 0 && filter.constructor === Object) {
          savedFilters.push(filter);
        }
        const requestBody = {
          filters: savedFilters,
          name,
        };
        if (savedFilters.length > 0) {
          this.createContactList({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, stage: this.stage, requestBody }).then(() => {
            this.enableLoad();
            this.filtersSelected = [];
            this.getIssuerContactList();
            this.clearAll();
          });
        } else {
          this.getIssuerContactList();
          this.clearAll();
        }
      }
    },
    getIssuerSavedContactListById(ids) {
      this.enableLoad();
      const savedFilters = this.getSavedContactListById(ids);
      const filterData = [];
      const actualFilters = this.getFilterValues;
      Object.assign(filterData, savedFilters);
      this.saveFilterListState({ filterData });
      if (Object.keys(actualFilters).length > 0 && actualFilters.constructor === Object) {
        filterData.push(this.getFilterValues);
      }
      this.filtersSelected = filterData;
      this.getIssuerContactList();
      if (ids.length === 0) {
        this.clearInvestorsOutreachList();
      }
    },
    setQueryParams(queryParams) {
      this.enableLoad();
      this.queryParams = queryParams;
      this.getIssuerContactList();
    },
    clearAll() {
      this.filtersSelected = [];
      this.$refs.outreachSavedContactLists.clearStore();
      this.$refs.outreachFilter.clearStore();
    },
    clearInvestorsOutreachList() {
      this.$refs.outreachSavedContactLists.clearStore();
    },
    handleSearch(val) {
      this.searchFilter = val;
    },
  },
};
</script>
<style scoped>

.content:after {
  content: "";
  display: table;
  clear: both;
}
.column {
  float: left;
  padding: 10px;
}

.card-body {
  padding: 0 10px;
}
.border{
    border-top: 0px !important;
  }
.sidebar {
  width: 25%
}

.body {
  width: 75%;
}
.card-header{
  padding: 0px;
  border-bottom: 1px solid #0a172715;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100% !important;
  }
}
</style>
