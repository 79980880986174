<template>
  <b-row>
    <b-col md="12">
      <cp-button
        variant="info"
        class="pt-1 pb-1 mr-2 float-left"
        size="md"
        @click="goBack"
      >
        <span>{{ $t('outreach.dashboard.emailActivity.back') }}</span>
      </cp-button>
    </b-col>
    <b-col md="12">
      <div class="float-left text-left mt-3">
        <p> <u>{{ $t('outreach.dashboard.emailActivity.emailSubject') }} {{ data.subject }}</u> </p>
        <p> <u>{{ $t('outreach.dashboard.emailActivity.dateSent') }} {{ getDate(data.date) }} </u></p>
      </div>
    </b-col>
    <b-col>
      <out-table
        :items="data.data"
        :fields="tableFields"
        :query-params="queryParamsDetail"
        :total-items="totalItems"
        :busy="isLoading"
        @onPagination="$emit('onPagination', $event, 'detail')"
      >
        <template
          slot="delivered"
          slot-scope="{rowData}"
        >
          <i
            class="ion"
            :class="getIcon(rowData.item.events.indexOf('delivered'))"
          />
        </template>
        <template
          slot="bounce"
          slot-scope="{rowData}"
        >
          <i
            class="ion"
            :class="getIcon(rowData.item.events.indexOf('bounce'))"
          />
        </template>
        <template
          slot="open"
          slot-scope="{rowData}"
        >
          <i
            class="ion"
            :class="getIcon(rowData.item.events.indexOf('open'))"
          />
        </template>
        <template
          slot="click"
          slot-scope="{rowData}"
        >
          <i
            class="ion"
            :class="getIcon(rowData.item.events.indexOf('click'))"
          />
        </template>
        <template
          slot="unsubscribe"
          slot-scope="{rowData}"
        >
          <i
            class="ion"
            :class="getIcon(rowData.item.events.indexOf('unsubscribe'))"
          />
        </template>
        <template
          slot="spamreport"
          slot-scope="{rowData}"
        >
          <i
            class="ion"
            :class="getIcon(rowData.item.events.indexOf('spamreport'))"
          />
        </template>
      </out-table>
    </b-col>
  </b-row>
</template>

<script>

import moment from 'moment';
import { tableColumns } from '../options';
import CpButton from '~/components/common/standalone-components/cp-button';
import OutTable from '../shared/out-table';

export default {
  name: 'EmailActivityDetail',
  components: {
    CpButton,
    OutTable,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    queryParamsDetail: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableFields() {
      return tableColumns.emailActivityDetails;
    },
    totalItems() {
      return this.data.totalItems ? this.data.totalItems : 10;
    },
  },
  methods: {
    getIcon(event) {
      if (event !== -1) {
        return 'ion-md-checkmark';
      }
      return 'ion-md-remove';
    },
    goBack() {
      this.$emit('onGoBack');
    },
    getDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
};
</script>

<style scoped>
  .ion-md-checkmark{
    color: #04bec4;
  }
  .ion {
    font-size: 1.3rem !important;
  }
</style>
