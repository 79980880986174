import _ from 'lodash';

function cleanEmptyContent(el) {
  function internalClean(internalEl) {
    return _.transform(internalEl, (result, value, key) => {
      const isCollection = _.isObject(value);
      const cleaned = isCollection ? internalClean(value) : value;

      if ((isCollection && _.isEmpty(cleaned)) || cleaned === '') {
        return;
      }
      if (_.isArray(result)) {
        result.push(cleaned);
      } else {
        result[key] = cleaned;
      }
    });
  }

  return _.isObject(el) ? internalClean(el) : el;
}

export default cleanEmptyContent;
