<template>
  <div
    align="center"
    body-class="card-content"
  >
    <img
      :src="EmailIcon"
      class="mail-icon mb-3"
    >
    <b-card-text>
      {{ $t('outreach.dashboard.message.causeDescription') }}
    </b-card-text>
    <b-card-text>
      {{ $t('outreach.dashboard.message.actionDescription') }}
    </b-card-text>
  </div>
</template>
<script>
import EmailIcon from '~/assets/img/Email.svg';

export default {
  name: 'OutreachDomainInstructions',
  data() {
    return {
      EmailIcon,
    };
  },
};
</script>
