<template>
  <component-header
    :title="headerTitle"
    :collapse="collapseId"
  >
    <cp-input-container
      v-model="filterData"
      class="mb-1 pt-2"
      :default-value="getDefaultValue"
      @edited="editHandler"
    >
      <cp-select
        v-if="stage == 'fundraise'"
        name="rounds"
        class="pb-1"
        :options="roundsList"
        input-class="form-control"
      />
      <div v-if="stage == 'fundraise'">
        <cp-select
          v-for="(filter, index) in filterListsRounds"
          :key="index"
          class="pb-1"
          :disabled="filter.disabled"
          :name="filter.name"
          :options="filter.options"
          input-class="form-control"
        />
      </div>
      <cp-select
        name="country"
        class=" pb-1"
        :options="countriesList"
        input-class="form-control"
      />
      <cp-select
        v-for="(filter, index) in filterLists"
        :key="index"
        class="pb-1"
        :disabled="filter.disabled"
        :name="filter.name"
        :options="filter.options"
        input-class="form-control"
      />
      <cp-select
        v-if="stage == 'fundraise' || stage == 'holders'"
        name="labels"
        class="pb-1"
        :options="labelsLists"
        input-class="form-control"
      />
    </cp-input-container>
  </component-header>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import CpInputContainer from '~/components/common/cpInputContainer';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import ComponentHeader from '../shared/component-header';

import { filters } from '../options';
import filterParseForApi from '~/utilities/investorFilterParseForApi';

export default {
  name: 'OutreachFilter',
  components: {
    CpInputContainer,
    CpSelect,
    ComponentHeader,
  },
  props: {
    stage: {
      type: String,
      default: 'onboarding',
    },
  },
  data() {
    return {
      headerTitle: 'Filter Contact List',
      collapseId: 'filter',
      name: 'investorsOutreach',
      filterData: {},
    };
  },
  computed: {
    ...mapGetters('global', ['getFilterState', 'countries']),
    ...mapGetters('rounds', ['rounds']),
    ...mapGetters('investors', ['labelsList']),
    getDefaultValue() {
      return this.getFilterState(this.name);
    },
    labelsLists() {
      return [
        { text: this.$t('outreach.label.filter.labels'), value: null },
        ...(this.labelsList.map(
          labelsList => ({ text: labelsList, value: labelsList }),
        )),
      ];
    },
    roundsList() {
      return [
        { text: this.$t('outreach.label.filter.rounds'), value: null },
        ...(this.rounds.map(
          rounds => ({ text: rounds.name, value: rounds.id }),
        )),
      ];
    },
    countriesList() {
      return [
        { text: this.$t('outreach.label.filter.country'), value: null },
        ...(this.countries.map(
          country => ({ text: country.name, value: country.countryCode }),
        )),
      ];
    },
    filterLists() {
      return filters[this.stage];
    },
    filterListsRounds() {
      return filters.fundraiseRounds;
    },
  },
  watch: {
    'filterData.rounds': function rounds(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.roundsSelected();
        this.$emit('onRoundChange');
      }
    },
  },
  beforeMount() {
    this.clearStore();
  },
  methods: {
    ...mapMutations('global', ['SET_FILTER_STATE']),
    updateStore() {
      const { name, filterData } = this;
      Object.keys(this.filterData).forEach((key) => {
        if (this.filterData[key] === null || this.filterData[key] === undefined) {
          delete this.filterData[key];
        }
      });
      this.SET_FILTER_STATE({ name, filterData });
    },
    clearStore() {
      this.filterData = {};
      this.roundsSelected();
      this.updateStore();
    },
    editHandler(isEdited) {
      if (isEdited) {
        Promise.all([this.updateStore()])
          .finally(() => this.$emit('onUpdate', this.getActiveFilters()));
      }
    },
    getActiveFilters() {
      return filterParseForApi(this.getDefaultValue);
    },
    roundsSelected() {
      if (this.stage === 'fundraise') {
        this.filterListsRounds.forEach((item) => {
          item.disabled = !this.filterData.rounds || false;
        });
      }
    },
  },
};
</script>
