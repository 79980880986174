<template>
  <b-row>
    <b-col
      cols="8"
      md="12"
      offset="2"
      offset-md="0"
      class="hubspot"
    >
      <b-row
        no-gutters
      >
        <b-col
          sm="12"
          xs="12"
          md="6"
        >
          <b-card class="left-block">
            <h3 class="mt-4">
              {{ $t('outreach.upgradeMessage') }}
            </h3>
            <div class="mt-5">
              <span>{{ $t('outreach.upgradeText') }}</span>
            </div>
            <div class="center-div">
              <a
                :href="`https://share.hsforms.com/${id}`"
                target="_blank"
              >  <b-button
                variant="info"
                class="mt-4 center-item contact-button "
              >{{
                $t('outreach.upgradeButton')
              }}</b-button></a>
            </div>
          </b-card>
        </b-col>
        <b-col
          sm="12"
          xs="12"
          md="6"
        >
          <b-card
            class="right-block"
          >
            <img
              :src="upgradeIssuer"
              class="mail-icon mb-3"
            >
            <div class="info center-div">
              <span class=" info-text center-item"> {{ $t('outreach.upgradeInfo') }}</span>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import upgradeIssuer from '~/assets/img/upgrade-issuer.svg';

export default {
  name: 'CpHubspot',
  data() {
    return {
      upgradeIssuer,
      id: '',
    };
  },
  mounted() {
    this.id = process.env.VUE_APP_HUBSPOT_STATIC;
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-right: 0px;
  border-left: 0px;
}
h3{
  font-weight: 900;
}
.info{
  text-align: center;
  font-weight: lighter;
  text-justify: inter-word;
  width: 70%;
  display: block;
  margin: auto;
  bottom: 30px;
}
.left-block{
  background-color: rgba(97, 125, 141, 0.06);
  border-top-right-radius: 0px;
  min-height: 350px;
}
.right-block{
  border-top-left-radius: 0px;
  min-height: 350px;
}
.center-div{
  position: absolute;
  left: 50%;
}
.center-item{
  position: relative;
  left: -50%;
}
.contact-button{
  width: 200px;
  bottom: -50px;
}
@media screen and (max-width: 1225px) {
  .contact-button{
    width: 180px;
    bottom: -10px;
  }
  .info{
    bottom: 10px;
  }
}
@media screen and (max-width: 385px) {
  .left-block{
    min-height: 370px;
  }
  .right-block{
    min-height: 370px;
  }
}
.toast-error{
  display: none !important;
}
</style>
