<template>
  <div>
    <out-table
      :items="data"
      :fields="tableFields"
      :query-params="queryParams"
      :total-items="totalItems"
      @onPagination="$emit('onPagination', $event, 'activity')"
    >
      <template
        slot="subject"
        slot-scope="{ rowData }"
      >
        <span class="long-text">{{ rowData.value }}</span>
      </template>
      <template
        slot="date"
        slot-scope="{ rowData }"
      >
        {{ getDate(rowData.value) }}
      </template>
      <template
        slot="id"
        slot-scope="{ rowData }"
      >
        <cp-button
          size=""
          variant="outline-info"
          :value="rowData.value"
          @click="getDatails(rowData.value)"
        >
          {{ $t('outreach.dashboard.emailActivity.details') }}
        </cp-button>
      </template>
    </out-table>
  </div>
</template>

<script>
import moment from 'moment';
import { tableColumns } from '../options';
import CpButton from '~/components/common/standalone-components/cp-button';
import OutTable from '../shared/out-table';

export default {
  name: 'EmailActivity',
  components: {
    CpButton,
    OutTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    totalItems: {
      type: Number,
      default: 10,
    },
    queryParams: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tableFields() {
      return tableColumns.emailActivity;
    },
  },
  methods: {
    getDatails(id) {
      this.$emit('onDetail', id);
    },
    getDate(date) {
      return moment(date).format('DD.MM.YY');
    },
  },
};
</script>

<style scoped>
  .long-text{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display:inline-block;
    width: 190px;
  }
</style>
