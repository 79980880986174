<template>
  <ul class="nav nav-tabs tabs-alt nav-responsive-xl">
    <li
      v-for="tab in tabs"
      :key="tab.value"
      v-b-tooltip.hover.topright="tooltip(tab)"
      class="nav-item"
      @click="handleTabSelect(tab)"
    >
      <span
        :class="getNavLinkClass(tab)"
      >
        {{ tab.text }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  created() {
    if (!this.value) this.handleTabSelect(this.tabs[0]);
  },
  methods: {
    getNavLinkClass({ value, enabled }) {
      let classes = 'nav-link';
      if (this.value === value) {
        classes = `${classes} active`;
      }
      if (!enabled) {
        classes = `${classes} disabled`;
      }
      return classes;
    },
    handleTabSelect({ value, enabled }) {
      if (enabled) {
        this.$emit('input', value);
      }
    },
    tooltip({ enabled, tooltip }) {
      if (!enabled) {
        return tooltip;
      }
      return '';
    },
  },
};
</script>

<style scoped>
.nav-link{
  cursor: pointer;
}

</style>
