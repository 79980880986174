<template>
  <b-card
    header-tag="header"
    class="content mb-3"
  >
    <template
      v-slot:header
    >
      <b-input-group class="mb-0">
        <b-input-group-prepend
          is-text
          :class="'p-0'"
        >
          <img
            v-if="svg"
            :src="personalInformation"
            class="icon-personal"
          >
          <i
            v-if="!svg"
            :class="['filter-ico ion', icon]"
            @click="iconClick"
          />
          <span class="bold">{{ title }}</span>
        </b-input-group-prepend>
        <template v-slot:append>
          <b-input-group-text
            class="icon"
            @click="toggleCollapse"
          >
            <i
              :class="collapsed ? 'ion ion-md-arrow-dropdown': 'ion ion-md-arrow-dropup'"
              style="cursor: pointer"
            />
          </b-input-group-text>
        </template>
      </b-input-group>
    </template>
    <b-collapse
      :id="collapse"
      visible
      scrollable
      class=""
    >
      <slot />
    </b-collapse>
  </b-card>
</template>

<script>
import personalInformation from '~/assets/img/outreach/personal-information.svg';

export default {
  name: 'ComponentHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    collapse: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'ion-ios-options',
    },
    svg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      personalInformation,
      collapsed: false,
    };
  },
  methods: {
    toggleCollapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapse);
      this.collapsed = !this.collapsed;
    },
    iconClick() {
      this.$emit('click');
    },
  },
};
</script>
<style scoped>
.filter-header {
  display: flex;
  align-items: center;
  margin: auto;
}
.filter-ico {
  margin-right: 10px;
  padding: 10px;
}
.btn {
  margin-left: 10px;
  padding: 10px;
}
.card-header {
  padding: 0px;
  border-bottom: 1px solid #0a172715;
}
.input-group {
  justify-content: space-between;
}
.input-group-text {
  border: 0px;
  padding: 0px;
}
.bold {
  font-weight: 450;
  font-size: 1rem
}
.input-group-append:after {
  content:"";
  position:absolute;
  top:20%;
  left: 1;
  width:1px;
  height:50%;
  background: #0a172715;
}
.ion{
  font-size: 18px;
  margin-right: 0px;
}
.icon{
  padding-right: 14px;
  padding-left: 8px;
}
.content {
  margin: 0 0 20px 0;
}
.card-body {
  /* padding: 10px 10px 10px 10px !important; */
  padding: 0px 10px 0px 10px !important

}
.header-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.scroll {
    max-height: 200px;
    overflow-y: auto;
}
::-webkit-scrollbar {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border: solid whiteSmoke 4px;
}
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}
.ion-md-add-circle {
  color: #04bec4;
}
.icon-personal{
  width: 38px;
  padding: 10px;
}
</style>
