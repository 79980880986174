<template>
  <div>
    <b-card
      align="center"
      class="multi-comp"
      body-class="card-content"
    >
      <component
        :is="currentComponent"
        :data="emailDetail"
        :query-params-detail="queryParamsDetail"
        :chart-data="emailActivityChartData"
        :is-loading="isLoading"
        @onGoBack="mainComponent"
        @submit="handleFormSubmit"
        @onPagination="setQueryParams"
      />
    </b-card>
    <b-card
      v-if="emailActivityIsVisible()"
      header-class="header-email-table"
      body-class="pr-0 pl-3 pt-0"
      :header="$t('outreach.dashboard.emailActivity.title')"
      class="mt-5"
    >
      <email-activity
        v-if="emailActivityIsVisible() && !isLoadingActivity"
        :data="emailActivityData"
        :query-params="queryParams"
        :total-items="emailActivityTotal"
        @onPagination="setQueryParams"
        @onDetail="getDetailPanel"
      />
      <div class="spinner-container">
        <b-spinner
          v-if="isLoadingActivity"
          class="spinner"
        />
      </div>
    </b-card>
  </div>
</template>
<script>
import Cookie from 'js-cookie';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { has } from 'lodash';
import CpOutreachForm from './form';
import CpOutreachDomainPendingStatus from './pending';
import CpOutreachChart from './chart';
import Spinner from './spinner';
import EmailActivity from './email-activity';
import EmailActivityDetail from './email-activity-detail';

export default {
  name: 'OutreachDashboard',
  components: {
    CpOutreachForm,
    CpOutreachDomainPendingStatus,
    CpOutreachChart,
    EmailActivity,
    Spinner,
  },
  data() {
    return {
      minutesInHour: 60,
      selectedId: 0,
      visibleMailActivity: false,
      isLoadingActivity: true,
      isLoading: true,
      currentComponent: '',
      queryParams: {
        page: 0,
        limit: parseInt(Cookie.get('perPage')) || 10,
        'order-direction': 'desc',
      },
      queryParamsDetail: {
        page: 0,
        limit: parseInt(Cookie.get('perPage')) || 10,
        'order-direction': 'desc',
      },
    };
  },
  computed: {
    ...mapGetters('outreach', ['getSavedDomain', 'getEmailActivityChart', 'getEmailActivityData', 'getSelectedIdDetailsData', 'getEmailActivityTotal', 'getIsAuthenticated']),
    savedDomain() {
      return this.getSavedDomain;
    },
    emailActivityData() {
      return this.getEmailActivityData();
    },
    emailActivityTotal() {
      return this.getEmailActivityTotal;
    },
    emailDetail() {
      return this.getSelectedIdDetailsData;
    },
    emailActivityChartData() {
      return this.getEmailActivityChart;
    },
    timezone() {
      return moment().utcOffset() / this.minutesInHour;
    },
    savedIsAuthenticated() {
      return this.getIsAuthenticated;
    },
  },
  watch: {
    savedDomain() {
      this.loadComponentByDomainStatus();
    },
  },
  beforeMount() {
    this.loadComponentByDomainStatus();
    this.checkActivity();
  },
  methods: {
    ...mapActions('outreach', ['validateDomain', 'emailActivityChart', 'emailActivity', 'emailActivityDetail', 'saveDomainData', 'clearSavedDomain', 'clearSelectedDetailData']),
    checkActivity() {
      this.isLoadingActivity = true;
      this.emailActivity({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, queryParams: this.queryParams }).then(() => {
        setTimeout(() => { this.isLoadingActivity = false; }, 1000);
      });
    },
    loadComponentByDomainStatus() {
      this.currentComponent = Spinner;
      if (this.savedDomain !== '') {
        if (this.savedIsAuthenticated) {
          this.emailActivityChart({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, timezone: this.timezone })
            .then(() => {
              this.currentComponent = CpOutreachChart;
            });
        } else {
          this.currentComponent = CpOutreachDomainPendingStatus;
        }
      } else {
        this.currentComponent = CpOutreachForm;
      }
      this.isLoading = false;
    },
    handleFormSubmit(form) {
      this.isLoading = true;
      const ccEmails = form.ccEmail;
      const body = {
        ...form,
        ccEmail: ccEmails ? ccEmails.split(',') : [],
      };

      this.validateDomain({ issuerId: this.$route.params.idIssuer, body })
        .then(() => {
          this.currentComponent = CpOutreachDomainPendingStatus;
          this.saveDomainData({ domain: `@${form.name}`, isAuthenticated: false });
          this.isLoading = false;
        });
    },
    getEmailActivity() {
      return this.emailActivity({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, queryParams: this.queryParams });
    },
    getEmailActivityDetail() {
      return this.emailActivityDetail({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, subjectId: this.selectedId, queryParams: this.queryParamsDetail });
    },
    setQueryParams(queryParams, type) {
      this.isBusy = true;
      if (type === 'detail') {
        this.queryParamsDetail = queryParams;
        this.getEmailActivityDetail();
      } else {
        this.queryParams = queryParams;
        this.getEmailActivity();
      }
    },
    getDetailPanel(id) {
      this.currentComponent = EmailActivityDetail;
      this.selectedId = id;
      this.isLoading = true;
      this.emailActivityDetail({ issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId, subjectId: id, queryParams: this.queryParamsDetail })
        .then(() => {
          this.isLoading = false;
        });
    },
    mainComponent() {
      this.isLoading = true;
      this.selectedId = 0;
      this.clearSelectedDetailData();
      this.loadComponentByDomainStatus();
    },
    emailActivityIsVisible() {
      if (has(this.emailDetail, 'id') || this.selectedId !== 0) {
        this.currentComponent = EmailActivityDetail;
        return false;
      }
      return true;
    },
  },
};
</script>
<style>
.card-domain {
  margin: 0 auto;
  float: none;
  min-width: 35%;
  max-width: 30%;
}
.spinner-container {
 display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;
    margin: 0;
}
.spinner {
  text-align: center;
  width: 3rem;
  height: 3rem;
}
.header-email-table {
  font-size: 1.2rem;
}
</style>
