<template>
  <cp-general-modal
    ref="viewSendedModal"
    size="sm"
    :hide-header="true"
    :hide-footer="true"
  >
    <b-row>
      <b-col md="12">
        <div class="spinner-container mx-auto text-center mt-2 modal-size">
          <i
            v-if="!isLoading"
            class="ion"
            :class="modalIcon"
          />
          <b-spinner
            v-if="isLoading"
            class="spinner"
          />
        </div>
      </b-col>
      <b-col md="12">
        <div class="mx-auto text-center modal-size mt-2">
          <span class>{{ message }}</span>
        </div>
      </b-col>
    </b-row>
  </cp-general-modal>
</template>

<script>

import CpGeneralModal from '~/components/common/modals-components/general-modal';


export default {
  name: 'LoadingModal',
  components: {
    CpGeneralModal,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    modalIcon: {
      type: String,
      default: 'ion-ios-options',
    },
  },
  data() {
    return {
    };
  },
  methods: {
    iconClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>

.ion {
  color: #04bec4;
  font-size: 4rem;
}

</style>
